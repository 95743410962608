import React, { useEffect, useState } from "react";
import { ApiService, ImgUrl } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GImageEditor from "../gComponents/gImageEditor";
import GButton from "../gComponents/gButton";
import useDataFctory from "../useDataFactory";

export default function DocumentForm(props) {
  const [isNoteShow, setIsNoteShow] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [openSnackbar] = useSnackbar();
  const { data: docTypesList } = useDataFctory("document/types", false, {
    user_type: props.userType,
  });
  const initialValues = {
    document_type_id: !props.isEmpty ? props.data.document_type_id : "",
    photo: !props.isEmpty ? props.data.photo : "",
    thumb_photo: !props.isEmpty ? props.data.thumb_photo : "",
    user_id: props.linkedId || "",
    user_type: props.userType || "",
  };

  const onSubmit = (values) => {
    setIsBtnDisabled(true);
    console.log(values);
    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty ? "documents/" + props.data.id : "documents";
    ApiService({ method: hitMethod, route: hitUrl, body: values }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.setDocvisible(false);
          props.onSubmit(response.data.data);
        }
        openSnackbar(response.data.message);
        setIsBtnDisabled(false);
      }
    );
  };

  const validationSchema = Yup.object({
    document_type_id: Yup.string().required("Required"),
  });

  if (props.docvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        {!props.isEmpty ? "Edit" : "Create"} Document
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values }) => (
            <Form>
              <GImageEditor
                onSubmit={(newValue, id, thumb_val) => {
                  values.photo = newValue;
                  values.thumb_photo = thumb_val;
                }}
                id="thumb_photo"
                image={
                  values.thumb_photo
                    ? ImgUrl("document") + "/" + values.thumb_photo
                    : ""
                }
                type="document"
                style={{ height: "160px", paddingTop: "60px" }}
                divstyle={{ margin: "0 auto", display: "block" }}
              />
              <br />

              {docTypesList ? (
                <React.Fragment>
                  <FormikControl
                    control="select"
                    as="select"
                    label="Document Types"
                    name="document_type_id"
                    options={docTypesList}
                    key_title="title"
                    key_value="id"
                  />
                  {isNoteShow && docTypesList.length === 0 ? (
                    <p
                      style={{
                        margin: "8px 15px",
                        fontStyle: "italic",
                        fontSize: "14px",
                        color: "#777",
                      }}
                    >
                      <strong>Note : </strong> Please add the Document Types
                      from the General settings
                    </p>
                  ) : (
                    ""
                  )}
                </React.Fragment>
              ) : (
                ""
              )}

              <br />
              <GButton
                type="submit"
                variant="condensed"
                disabled={isBtnDisabled}
                children={!props.isEmpty ? "Update" : "Create"}
                style={{ margin: "0 15px", width: "calc(100% - 30px)" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
