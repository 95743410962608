import React, { useEffect, useState } from "react";
import GTabs from "../gComponents/gTabs";
import { ApiService } from "../services";
import GButton from "../gComponents/gButton";
import GInput from "../gComponents/gInput";
import { useSnackbar } from "react-simple-snackbar";
import styled from "styled-components";
import TooltipComponent from "../gComponents/gTooltip";
import { Info } from "@material-ui/icons";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import { ReactComponent as Check } from "../assets/images/tick.svg";
import { ReactComponent as UnCheck } from "../assets/images/untick.svg";
import GText from "../gComponents/gText";
import GSwitch from "../gComponents/gSwitch";

const apiMethods = [
  { title: "GET", value: "get" },
  { title: "POST", value: "post" },
];
const fieldStyle = {
  display: "block",
  width: "350px",
  padding: "10px 15px 10px 0",
};
const lableStyle = {
  fontSize: "0.9rem",
  lineHeight: "1.5rem",
  padding: "3px",
  textTransform: "capitalize",
};

const P = styled.p`
  font-size: 13px;
  color: #a2a2a2;
  letter-spacing: 0.1px;
  line-height: 20px;
  font-style: italic;
  margin: 5px 3px 10px;
  span {
    color: #f8310e;
  }
`;

const RadioDiv = styled.div`
  display: inline-flex;
  margin: 5px 0 20px;
  .radio {
    margin-right: 20px;
    label {
      display: inline-flex;
      svg {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        &.checked {
          path {
            fill: #f8310e;
          }
        }
      }
    }
  }
`;

const smsTypes = ["twilio", "custom", "orange"];
export default function EmailSettings() {
  const [testPhoneVal, setTestPhoneVal] = useState("");
  const [typeValue, setTypeValue] = useState();
  const [smsSettingsData, setSmsSettingsData] = useState({
    twilio: {},
    custom_sms: {},
    orange: {},
  });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [testResponse, setTestResponse] = useState("");
  const [openSnackbar] = useSnackbar();

  function getSmsSettings() {
    ApiService({ method: "GET", route: "sms-settings" })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setSmsSettingsData(response.data.data);
          setTypeValue(
            response.data.data.default !== "custom_sms"
              ? response.data.data.default
              : "custom"
          );
        } else {
          console.log(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getSmsSettings();
  }, []);

  function updateSmsSettings() {
    var hitUrl = "sms-settings";

    let sendData = { ...smsSettingsData };
    sendData.default = typeValue === "custom" ? "custom_sms" : typeValue;
    if (typeValue === "twilio") {
      delete sendData.orange;
      delete sendData.custom_sms;
    }
    if (typeValue === "orange") {
      delete sendData.twilio;
      delete sendData.custom_sms;
    }
    if (typeValue === "custom") {
      delete sendData.orange;
      delete sendData.twilio;
    }
    console.log(sendData);
    ApiService({
      method: "PUT",
      route: hitUrl,
      body: sendData,
    })
      .then((response) => {
        console.log(response.data);
        openSnackbar(response.data.message);
        if (response.data.status_code === 1) {
          getSmsSettings();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function testSmsSettings() {
    ApiService({
      method: "POST",
      route: "sms-settings",
      body: {
        phone: testPhoneVal,
        driver: typeValue === "custom" ? "custom_sms" : typeValue,
      },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setTestPhoneVal("");
          setTestResponse(
            response.data.data
              ? JSON.stringify(response.data.data)
              : response.data.data
          );
          setTimeout(function () {
            setDialogOpen(true);
          }, 800);
        } else {
          openSnackbar(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="rightInnerView">
      <div
        style={{
          float: "right",
          marginTop: "20px",
          fontStyle: "italic",
          display: "flex",
        }}
      >
        <GText
          med
          g4
          text={"Default Method :"}
          style={{ margin: "2px 0", display: "inline" }}
        />
        &nbsp;
        <GText
          theme
          g4
          text={smsSettingsData?.default?.replace("_", " ") || ""}
          style={{ margin: "2px 0", textTransform: "capitalize", flex: "1" }}
        />
      </div>
      <GText
        bold
        g2
        text={"SMS Notification Settings"}
        style={{ margin: "15px 0" }}
      />

      <GTabs
        routes={smsTypes}
        active={typeValue}
        type="single"
        onClick={(newVal) => {
          setTypeValue(newVal);
          setTestPhoneVal("");
        }}
      ></GTabs>

      <div className="listPaper" style={{ padding: "30px" }}>
        {typeValue === "twilio" ? (
          <div>
            <div style={fieldStyle}>
              <label style={lableStyle}>Account SID</label>
              <GInput
                type="text"
                placeholder=""
                name="t_secret"
                id="t_secret"
                value={smsSettingsData.twilio.secret}
                display="block"
                onChange={(e) => {
                  smsSettingsData.twilio.secret = e.target.value;
                  setSmsSettingsData({
                    ...smsSettingsData,
                  });
                }}
                onBlur={(e) => {
                  smsSettingsData.twilio.secret = e;
                }}
              />
            </div>
            <div style={fieldStyle}>
              <label style={lableStyle}>Auth Token</label>
              <GInput
                type="text"
                placeholder=""
                name="t_token"
                id="t_token"
                value={smsSettingsData.twilio.token}
                display="block"
                onChange={(e) => {
                  smsSettingsData.twilio.token = e.target.value;
                  setSmsSettingsData({
                    ...smsSettingsData,
                  });
                }}
                onBlur={(e) => {
                  smsSettingsData.twilio.token = e;
                }}
              />
            </div>
            <div style={fieldStyle}>
              <label style={lableStyle}>
                Twilio number/ Sender ID/ Short Code
                <TooltipComponent
                  title="
                  Twilio have different options for this based on your country. Try contacting their support, if you are not able to find in your Twilio account.
"
                  position="bottom"
                  id="twilio_number"
                >
                  <Info fontSize="small" />
                </TooltipComponent>
              </label>
              <GInput
                type="text"
                placeholder=""
                name="t_url"
                id="t_url"
                value={smsSettingsData.twilio.url}
                display="block"
                onChange={(e) => {
                  smsSettingsData.twilio.url = e.target.value;
                  setSmsSettingsData({
                    ...smsSettingsData,
                  });
                }}
                onBlur={(e) => {
                  smsSettingsData.twilio.url = e;
                }}
              />
            </div>
            <GButton
              variant="condensed"
              children="Update"
              onClick={updateSmsSettings}
              style={{
                margin: "10px 5px",
              }}
            ></GButton>
          </div>
        ) : typeValue === "orange" ? (
          <div>
            <div style={fieldStyle}>
              <label style={lableStyle}>Client Id</label>
              <GInput
                type="text"
                placeholder=""
                name="clientId"
                id="clientId"
                value={smsSettingsData.orange?.client_id}
                display="block"
                onChange={(e) => {
                  smsSettingsData.orange.client_id = e.target.value;
                  setSmsSettingsData({
                    ...smsSettingsData,
                  });
                }}
                onBlur={(e) => {
                  smsSettingsData.orange.client_id = e;
                }}
              />
            </div>
            <div style={fieldStyle}>
              <label style={lableStyle}>Client Secret</label>
              <GInput
                type="text"
                placeholder=""
                name="clientSecret"
                id="clientSecret"
                value={smsSettingsData.orange?.client_secret}
                display="block"
                onChange={(e) => {
                  smsSettingsData.orange.client_secret = e.target.value;
                  setSmsSettingsData({
                    ...smsSettingsData,
                  });
                }}
                onBlur={(e) => {
                  smsSettingsData.orange.client_secret = e;
                }}
              />
            </div>
            <div style={fieldStyle}>
              <label style={lableStyle}>Sender Address</label>
              <GInput
                type="text"
                placeholder=""
                name="senderAddress"
                id="senderAddress"
                value={smsSettingsData.orange?.sender_address}
                display="block"
                onChange={(e) => {
                  smsSettingsData.orange.sender_address = e.target.value;
                  setSmsSettingsData({
                    ...smsSettingsData,
                  });
                }}
                onBlur={(e) => {
                  smsSettingsData.orange.sender_address = e;
                }}
              />
            </div>
            <div style={fieldStyle}>
              <label style={lableStyle}>Sender Name</label>
              <GInput
                type="text"
                placeholder=""
                name="senderName"
                id="senderName"
                value={smsSettingsData.orange.sender_name}
                display="block"
                onChange={(e) => {
                  smsSettingsData.orange.sender_name = e.target.value;
                  setSmsSettingsData({
                    ...smsSettingsData,
                  });
                }}
                onBlur={(e) => {
                  smsSettingsData.orange.sender_name = e;
                }}
              />
            </div>
            <GButton
              variant="condensed"
              children="Update"
              onClick={updateSmsSettings}
              style={{
                margin: "10px 5px",
              }}
            ></GButton>
          </div>
        ) : (
          <div>
            <div style={{ width: "80%" }}>
              <label style={lableStyle}>Method</label>
              <br />
              <RadioDiv>
                {apiMethods.map((m, i) => {
                  return (
                    <div className="radio" key={i}>
                      <label
                        onClick={() => {
                          smsSettingsData.custom_sms.method = m.value;
                          setSmsSettingsData({
                            ...smsSettingsData,
                          });
                        }}
                      >
                        <input
                          type="radio"
                          value={m.value}
                          checked={
                            smsSettingsData.custom_sms.method === m.value
                          }
                          onChange={() =>
                            (smsSettingsData.custom_sms.method = m.value)
                          }
                          style={{ display: "none" }}
                        />
                        {smsSettingsData.custom_sms.method === m.value ? (
                          <Check className="checked" />
                        ) : (
                          <UnCheck />
                        )}
                        {m.title}
                      </label>
                    </div>
                  );
                })}
              </RadioDiv>

              <br />
              <label style={lableStyle}>Url</label>
              <P>
                Use <span>[text]</span> for SMS text and <span>[phone]</span>
                for the recipient phone number.
                <br /> Ex:
                http://example.com/sms/api.php?to=[phone]&message=[text]
              </P>
              <GInput
                type="text"
                placeholder=""
                name="url"
                id="url"
                value={smsSettingsData.custom_sms.url}
                display="block"
                onChange={(e) => {
                  smsSettingsData.custom_sms.url = e.target.value;
                  setSmsSettingsData({
                    ...smsSettingsData,
                  });
                }}
                onBlur={(e) => {
                  smsSettingsData.custom_sms.url = e;
                }}
              />

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "10px 0",
                }}
              >
                <label style={lableStyle}>Basic Auth</label>&nbsp;
                <GSwitch
                  id="basic_auth_userpwd"
                  uId="validFrom"
                  currentValue={smsSettingsData.custom_sms.basic_auth_userpwd}
                  style={{ width: "90px" }}
                  onSwitchChange={(val, id) => {
                    smsSettingsData.custom_sms.basic_auth_userpwd = val;
                    setSmsSettingsData({
                      ...smsSettingsData,
                    });
                  }}
                />
              </div>

              {smsSettingsData.custom_sms.basic_auth_userpwd == 1 && (
                <>
                  <label style={lableStyle}>Basic Auth Username</label>
                  <GInput
                    type="text"
                    placeholder=""
                    name="basic_auth_username"
                    id="basic_auth_username"
                    value={smsSettingsData.custom_sms.basic_auth_username}
                    display="block"
                    onChange={(e) => {
                      smsSettingsData.custom_sms.basic_auth_username =
                        e.target.value;
                      setSmsSettingsData({
                        ...smsSettingsData,
                      });
                    }}
                    onBlur={(e) => {
                      smsSettingsData.custom_sms.basic_auth_username = e;
                    }}
                  />
                  <label style={lableStyle}>Basic Auth Password</label>
                  <GInput
                    type="text"
                    placeholder=""
                    name="basic_auth_password"
                    id="basic_auth_password"
                    value={smsSettingsData.custom_sms.basic_auth_password}
                    display="block"
                    onChange={(e) => {
                      smsSettingsData.custom_sms.basic_auth_password =
                        e.target.value;
                      setSmsSettingsData({
                        ...smsSettingsData,
                      });
                    }}
                    onBlur={(e) => {
                      smsSettingsData.custom_sms.basic_auth_password = e;
                    }}
                  />
                </>
              )}
            </div>
            <br />
            <GButton
              variant="condensed"
              children="Update"
              onClick={updateSmsSettings}
            ></GButton>
          </div>
        )}
        <hr style={{ margin: "20px 0" }} />
        <GText
          text={"Check " + typeValue + " Integration"}
          style={{ textTransform: "capitalize" }}
        />
        <div
          style={{
            display: "inline-flex",
            verticalAlign: "middle",
          }}
        >
          <input
            type="text"
            value={testPhoneVal}
            placeholder="+1234567890"
            name="testNo"
            id="testNo"
            display="block"
            onChange={(event) => {
              const re = /^[0-9+\b]+$/;
              if (event.target.value && !re.test(event.target.value)) {
                return;
              } else {
                setTestPhoneVal(event.target.value);
              }
            }}
            style={{
              padding: "10px",
              margin: "4px",
              outline: "0",
              border: "1px solid #ccc",
            }}
          />
          <GButton
            variant="condensed"
            children="Send Test SMS"
            onClick={testSmsSettings}
            style={{
              height: "38px",
              marginTop: "4px",
            }}
          ></GButton>
        </div>
      </div>

      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title={
            typeValue?.charAt(0).toUpperCase() +
            typeValue?.slice(1) +
            " Gateway Response"
          }
          text={testResponse}
          handleClose={() => setDialogOpen(false)}
          handleCloseText="Okay"
        ></GConfirmDialog>
      ) : (
        ""
      )}
    </div>
  );
}
