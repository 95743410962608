import React from "react";
import styled from "styled-components";
import { object } from "yup/lib/locale";

const Text = styled.p`
  color: #000;
  font-weight: 450;
  margin: 10px 0;
  padding: 0px;
  display: block;
  width: 100%;
`;

const GText = (props) => {
  // export default function GText(props) {
  // console.log(props);

  function getFontSize() {
    if (props.g1) {
      return "28px";
    } else if (props.g2) {
      return "24px";
    } else if (props.g3) {
      return "20px";
    } else if (props.g4) {
      return "17px";
    } else if (props.g5) {
      return "14px";
    } else {
      return "15px";
    }
  }

  function getFontweight() {
    if (props.bold) {
      return "700";
    } else if (props.semi) {
      return "550";
    } else if (props.thin) {
      return "400";
    } else return "450";
  }

  function getFontColor() {
    if (props.theme) {
      return "#F8310E";
    } else if (props.light) {
      return "#a2a2a2";
    } else if (props.med) {
      return "#555";
    } else return "#000";
  }

  return (
    <Text
      onClick={(e) => {
        if (props.onClick) {
          props.onClick(e);
        }
      }}
      style={Object.assign(
        {},
        {
          fontSize: getFontSize(),
          fontWeight: getFontweight(),
          color: getFontColor(),
        },
        props.style
      )}
    >
      {props.text}
    </Text>
  );
};

export default GText;
