import React, { useState } from "react";
import "./sidebar.css";
import CustomerCreate from "../forms/customerForm";
import HostForm from "../forms/hostForm";
import { Link } from "react-router-dom";
import useSettings from "../useSettings";
import { addDefaultLogo, ImgUrl } from "../services";
import GNestedMenu from "../gComponents/gNestedMenu";
import Modal from "react-modal";

export default function Sidebar(props) {
  const [ccvisible, setCcvisible] = useState(false);
  const [chvisible, setChvisible] = useState(false);
  const settings = useSettings(true);
  return (
    <div className="Sidebar" style={props.style}>
      <div className="App-header">
        <Link to="/dashboard">
          <img
            src={ImgUrl("logo") + "/" + settings?.admin_logo}
            className="App-logo"
            alt="logo"
            onError={addDefaultLogo}
          />
        </Link>
        <span>
          <h3>{settings.project_name}</h3>
          <p></p>
        </span>
      </div>

      <GNestedMenu
        settings={settings}
        handleClick={(title) => {
          // eslint-disable-next-line no-unused-expressions
          title === "Customers"
            ? setCcvisible(true)
            : title === "Hosts"
            ? setChvisible(true)
            : "";
        }}
      />

      <div
        className="Sidebar-footer"
        style={{ position: props.toggle == true ? "inherit" : "fixed" }}
      >
        <hr />
        <p>
          Powered by{" "}
          <a
          // target="_blank"
          // rel="noopener noreferrer"
          // href=""
          >
            Unify
          </a>
        </p>
      </div>

      <Modal
        isOpen={chvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setChvisible(false)}
        style={{
          content: {
            width: "400px",
          },
        }}
        contentLabel="Driver Form Modal"
      >
        <HostForm
          chvisible={chvisible}
          setChvisible={setChvisible}
          isEmpty={true}
        />
      </Modal>

      <Modal
        isOpen={ccvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCcvisible(false)}
        style={{
          content: {
            width: "420px",
          },
        }}
        contentLabel="Customer Form Modal"
      >
        <CustomerCreate
          isEmpty={true}
          ccvisible={ccvisible}
          setCcvisible={setCcvisible}
        />
      </Modal>
    </div>
  );
}
