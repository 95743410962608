import React, { useState, useContext } from "react";
import { ApiService } from "../services";
import GButton from "../gComponents/gButton";
import GListCard from "../gComponents/gListCards";
import GroupsForm from "../forms/productGroupForm";
import { useSnackbar } from "react-simple-snackbar";
import Modal from "react-modal";
import useDataFctory from "../useDataFactory";

export default function ProductGroups() {
  const [openSnackbar] = useSnackbar();
  const [isEmpty, setIsEmpty] = useState(true);
  const [selectedGroup, setSelectedGroup] = useState({});
  const [groupVisible, setGroupVisible] = useState(false);
  const {
    loading,
    data: groupsData,
    Placeholder,
    TableShimmer,
    refreshData,
  } = useDataFctory("product_groups", false);

  function deleteStoreGroup(selectedId) {
    ApiService({ method: "DELETE", route: "product/groups/" + selectedId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          let selectedIndex = groupsData.findIndex((p) => p.id == selectedId);
          if (selectedIndex > -1) {
            groupsData.splice(selectedIndex, 1);
          }
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="rightInnerView">
      <h2>
        Product Groups
        <GButton
          variant="condensed"
          children={"Add"}
          onClick={() => {
            setSelectedGroup({});
            setIsEmpty(true);
            setGroupVisible(true);
          }}
          style={{
            float: "right",
          }}
        />
      </h2>
      <div className="listPaper">
        {loading === true ? (
          <TableShimmer></TableShimmer>
        ) : !groupsData || groupsData?.length === 0 ? (
          <Placeholder />
        ) : (
          <div>
            {groupsData &&
              groupsData.map((d) => {
                return (
                  <GListCard
                    setting={"Product Group"}
                    title={d.title}
                    listActions={true}
                    key={d.id}
                    id={d.id}
                    edit={true}
                    delete={true}
                    onEdit={(selectedRecord) => {
                      setSelectedGroup(selectedRecord);
                      setIsEmpty(false);
                      setGroupVisible(true);
                    }}
                    onDelete={deleteStoreGroup}
                    data={d}
                  ></GListCard>
                );
              })}
          </div>
        )}
      </div>

      <Modal
        isOpen={groupVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setGroupVisible(false)}
        style={{
          content: {
            width: "380px",
          },
        }}
        contentLabel="Group Form Modal"
      >
        <GroupsForm
          groupVisible={groupVisible}
          setGroupVisible={setGroupVisible}
          data={selectedGroup}
          isEmpty={isEmpty}
          onSubmit={(data) => {
            let selectedIndex = groupsData.findIndex((p) => p.id == data.id);
            if (selectedIndex > -1) {
              groupsData[selectedIndex] = { ...data };
            } else {
              groupsData.push(data);
            }
          }}
        />
      </Modal>
    </div>
  );
}
