import React, { useState } from "react";
import { ApiService, ImgUrl } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GImageEditor from "../gComponents/gImageEditor";
import GButton from "../gComponents/gButton";
import ColorPicker from "../gComponents/gColorPicker";

const imgPositions = [
  { title: "Left", value: "left" },
  { title: "Right", value: "right" },
];
export default function WebPluginForm(props) {
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [openSnackbar] = useSnackbar();
  const initialValues = {
    title: !props.isEmpty ? props.data.title : "",
    description: !props.isEmpty ? props.data.description : "",
    image: !props.isEmpty ? props.data.image : "",
    thumb_image: !props.isEmpty ? props.data.thumb_image : "",
    align: !props.isEmpty ? props.data.align : "left",
    b_title: !props.isEmpty ? props.data.b_title : "",
    b_link: !props.isEmpty ? props.data.b_link : "",
    color: !props.isEmpty ? props.data.color : "#ffffff",
  };

  console.log(props.data + "--" + props.isEmpty);

  const onSubmit = (values) => {
    setIsBtnDisabled(true);
    console.log(values);
    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty
      ? "wp/homepage/" + props.data.id
      : "wp/homepage";
    ApiService({ method: hitMethod, route: hitUrl, body: values }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.setWebPvisible(false);
          props.onSubmit(response.data.data);
        }
        openSnackbar(response.data.message);
        setIsBtnDisabled(false);
        return;
      }
    );
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    align: Yup.string().required("Required"),
    image: Yup.string().required("Required"),
  });

  if (props.webpvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        {!props.isEmpty ? "Edit" : "Add"} Plugin
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <label style={{ margin: "0 15px" }}>Image*</label>
              <GImageEditor
                onSubmit={(newValue, id, thumb_val) => {
                  values.image = newValue;
                  values.thumb_image = thumb_val;
                  setFieldValue("image", newValue);
                  setFieldValue("thumb_image", thumb_val);
                }}
                id="thumb_image"
                image={
                  values.thumb_image
                    ? ImgUrl("web") + "/" + values.thumb_image
                    : ""
                }
                type="web"
                style={{ height: "160px", paddingTop: "60px" }}
                divstyle={{
                  width: "calc(100% - 10px)",
                  display: "block",
                  padding: "5px 10px",
                }}
              />
              <FormikControl
                control="input"
                type="text"
                name="image"
                style={{ display: "none" }}
              />

              <FormikControl
                control="radio"
                label="Image Position*"
                name="align"
                options={imgPositions}
                key_title="title"
                key_value="value"
                divstyle={{ display: "block", paddingBottom: "6px" }}
              />

              <FormikControl
                control="input"
                type="text"
                label="Title*"
                name="title"
              />
              <FormikControl
                control="input"
                as="textarea"
                label="Description*"
                name="description"
                rows="4"
              />

              <FormikControl
                control="input"
                type="text"
                label="Button Title"
                name="b_title"
              />
              <FormikControl
                control="input"
                type="text"
                label="Button Link"
                name="b_link"
              />
              <div style={{ margin: "0 15px 20px" }}>
                <label style={{ margin: "5px 0", display: "block" }}>
                  Color
                </label>
                <ColorPicker
                  id={"pluginColor"}
                  defaultcolor={values.color}
                  onChange={(newVal, fieldId) => {
                    setFieldValue("color", newVal);
                  }}
                />
              </div>

              <br />
              <GButton
                type="submit"
                variant="condensed"
                disabled={isBtnDisabled}
                children={!props.isEmpty ? "Update" : "Create"}
                style={{ margin: "0 15px", width: "calc(100% - 30px)" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
