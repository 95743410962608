import React, { useEffect, useState, useReducer, useRef } from "react";
import GTabs from "../gComponents/gTabs";
import { ApiService } from "../services";
import GButton from "../gComponents/gButton";
import GInput from "../gComponents/gInput";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import { useSnackbar } from "react-simple-snackbar";
import GText from "../gComponents/gText";

const emailTypes = ["system", "2_factor"];

export default function EmailSettings({ match }) {
  const [testPhoneVal, setTestPhoneVal] = useState("");
  const [typeValue, setTypeValue] = useState();
  const [smsOtpSettingsData, setSmsOtpSettingsData] = useState({});
  const [openSnackbar] = useSnackbar();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [testResponse, setTestResponse] = useState("");
  const [systemData] = useState({ default: "system" });
  const [otp2FactorData, setOtp2FactorData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      default: "otp_2factor",
      mailgun: {
        api_key: "",
      },
    }
  );

  const fieldStyle = {
    display: "block",
    width: "300px",
    padding: "10px 15px 10px 0",
  };
  const lableStyle = {
    fontSize: "0.9rem",
    lineHeight: "1.5rem",
    padding: "3px",
    textTransform: "capitalize",
  };

  function getEmailSettings() {
    ApiService({ method: "GET", route: "sms-otp-settings" })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setSmsOtpSettingsData(response.data.data);
          setTypeValue(
            response.data.data.default != "otp_2factor"
              ? response.data.data.default
              : "2_factor"
          );
          setOtp2FactorData({
            otp_2factor: {
              api_key: response.data.data.otp_2factor.api_key,
            },
          });
        } else {
          console.log(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    console.log(typeValue);
    getEmailSettings();
  }, []);

  function updateSmsOtpSettings() {
    let sendData = { ...otp2FactorData };
    sendData.default = typeValue === "2_factor" ? "otp_2factor" : typeValue;

    ApiService({
      method: "PUT",
      route: "sms-otp-settings",
      body: typeValue === "system" ? systemData : sendData,
    })
      .then((response) => {
        console.log(response.data);
        openSnackbar(response.data.message);
        if (response.data.status_code === 1) {
          getEmailSettings();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function testSmsOtpSettings() {
    ApiService({
      method: "POST",
      route: "sms-otp-settings",
      body: {
        phone: testPhoneVal,
        driver: typeValue === "2_factor" ? "otp_2factor" : typeValue,
      },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setTestPhoneVal("");
          setTestResponse(
            response.data.data
              ? JSON.stringify(response.data.data)
              : response.data.data
          );
          setTimeout(function () {
            setDialogOpen(true);
          }, 800);
        } else {
          openSnackbar(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="rightInnerView">
      <div
        style={{
          float: "right",
          marginTop: "20px",
          fontStyle: "italic",
          display: "flex",
        }}
      >
        <GText
          med
          g4
          text={"Default Method :"}
          style={{ margin: "2px 0", display: "inline" }}
        />
        &nbsp;
        <GText
          theme
          g4
          text={smsOtpSettingsData?.default?.replace("_", " ") || ""}
          style={{ margin: "2px 0", textTransform: "capitalize", flex: "1" }}
        />
      </div>

      <GText
        bold
        g2
        text={"SMS OTP Notification Settings"}
        style={{ margin: "15px 0" }}
      />

      <GTabs
        routes={emailTypes}
        active={typeValue}
        type="single"
        onClick={(newVal) => {
          setTypeValue(newVal);
          setTestPhoneVal("");
        }}
      ></GTabs>

      <div className="listPaper" style={{ padding: "30px" }}>
        {typeValue === "system" ? (
          <div>
            <p>We will use the transactional SMS Setting to send OTP.</p>
            <GButton
              variant="condensed"
              children="Set as Default"
              onClick={updateSmsOtpSettings}
              disabled={smsOtpSettingsData.default === "system" ? true : false}
            ></GButton>
          </div>
        ) : typeValue === "2_factor" ? (
          <div>
            <div style={fieldStyle}>
              <label style={lableStyle}>Api Key</label>
              <GInput
                type="text"
                placeholder=""
                name="api_key"
                id="api_key"
                value={otp2FactorData?.otp_2factor?.api_key}
                display="block"
                onChange={(e) => {
                  otp2FactorData.otp_2factor.api_key = e.target.value;
                  setOtp2FactorData({ ...otp2FactorData });
                }}
              />
            </div>
            <br />
            <GButton
              variant="condensed"
              children="Update"
              onClick={updateSmsOtpSettings}
            ></GButton>
          </div>
        ) : (
          ""
        )}

        {typeValue === "2_factor" &&
          smsOtpSettingsData.default === "otp_2factor" && (
            <React.Fragment>
              <hr style={{ margin: "20px 0" }} />
              <GText
                text={"Check " + typeValue.replace("_", " ") + " Integration"}
                style={{ textTransform: "capitalize", margin: "5px" }}
              />
              <div
                style={{
                  display: "inline-flex",
                  verticalAlign: "middle",
                }}
              >
                <input
                  type="text"
                  value={testPhoneVal}
                  placeholder="+1234567890"
                  name="testNo"
                  id="testNo"
                  display="block"
                  onChange={(event) => {
                    const re = /^[0-9+\b]+$/;
                    if (event.target.value && !re.test(event.target.value)) {
                      return;
                    } else {
                      setTestPhoneVal(event.target.value);
                    }
                  }}
                  style={{
                    padding: "10px",
                    margin: "4px",
                    outline: "0",
                    border: "1px solid #ccc",
                  }}
                />
                <GButton
                  variant="condensed"
                  children="Send Test SMS OTP"
                  onClick={testSmsOtpSettings}
                  style={{
                    height: "38px",
                    marginTop: "4px",
                  }}
                ></GButton>
              </div>
            </React.Fragment>
          )}
      </div>

      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title={"Response"}
          text={testResponse}
          handleClose={() => setDialogOpen(false)}
          handleCloseText="Okay"
        ></GConfirmDialog>
      )}
    </div>
  );
}
