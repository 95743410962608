import React, { useState, useEffect } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
} from "../styledComponents/tableStyle";
import GEditable from "../gComponents/gEditable";
import GText from "../gComponents/gText";

export default function BookingSettings() {
  const [orderSettingsData, setOrderSettingsData] = useState({
    booking_duration: {},
  });
  const [openSnackbar] = useSnackbar();

  const getBookingSettings = () => {
    ApiService({ method: "GET", route: "setting/booking" })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setOrderSettingsData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getBookingSettings();
  }, []);

  function updateSetting(newValue, selectedFieldId, keyTitle) {
    ApiService({
      method: "PUT",
      route: "setting/" + selectedFieldId,
      body: {
        value: newValue,
      },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          if (keyTitle) {
            orderSettingsData[keyTitle] = response.data.data;
            setOrderSettingsData({ ...orderSettingsData });
          }
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="rightInnerView">
      <GText g2 bold text={"Basic Settings"} style={{ marginBottom: "20px" }} />
      <div className="listPaper">
        <Table>
          <TableBody>
            {orderSettingsData.booking_duration?.id && (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                style={{ background: "#fff" }}
              >
                <TableRowData>
                  {orderSettingsData.booking_duration?.title ||
                    "Booking Duration (In Mins)"}
                </TableRowData>
                <TableRowData></TableRowData>
                <TableRowData style={{ width: "200px", padding: "0" }}>
                  <GEditable
                    type="number"
                    title={false}
                    editable={false}
                    defaultValue={orderSettingsData.booking_duration.value}
                    id={orderSettingsData.booking_duration.id}
                    onSubmit={(newVal, id) => {
                      orderSettingsData.booking_duration.value = newVal;
                      setOrderSettingsData(orderSettingsData);
                      updateSetting(newVal, id, "max_list_distance");
                    }}
                    style={{ margin: "0px 0 10px", width: "80%" }}
                    inputStyle={{ minWidth: "80px", width: "60%" }}
                  />
                </TableRowData>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
