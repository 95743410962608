import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";
import GTabs from "../gComponents/gTabs";
import Child from "./orderCancelReasons";
import GText from "../gComponents/gText";

const docRoutes = [
  {
    title: "customer",
    link: "/settings/order-cancel-reasons/customer",
    state: "setting",
  },
  {
    title: "host",
    link: "/settings/order-cancel-reasons/host",
    state: "setting",
  },
];

export default function OrderCancelReasons({ match }) {
  const [typeValue, setTypeValue] = useState("customer");

  function handleChange(newValue) {
    setTypeValue(newValue);
  }

  return (
    <div className="rightInnerView">
      <GText
        g2
        bold
        text={"Booking Cancel Reasons"}
        style={{ marginBottom: "20px" }}
      />
      <GTabs routes={docRoutes} active={typeValue}></GTabs>

      <Switch>
        <div className="listPaper">
          <Route path="/settings/order-cancel-reasons/:type">
            <Child onChange={handleChange} />
          </Route>
        </div>
      </Switch>
    </div>
  );
}
