import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ApiService } from "../services";
import { TableContainer } from "@material-ui/core";
import {
  Add,
  Edit,
  Delete,
  ThumbDownAlt,
  ThumbUpAlt,
} from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "./../styledComponents/tableStyle";
import GEmpty from "../gComponents/gEmpty";
import GTableShimmer from "../gComponents/gTableShimmer";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GButton from "../gComponents/gButton";
import ProgressBar from "../gComponents/gProgressBar";
import FaqForm from "../forms/faqForm";
import { useSnackbar } from "react-simple-snackbar";
import Modal from "react-modal";

const columns = [
  { id: "question", label: "Question", minWidth: 200 },
  { id: "votes", label: "Votes", minWidth: 200, align: "center" },
  { id: "type", label: "Type", minWidth: 120, align: "center" },
  { id: "actions", label: "Actions", minWidth: 60, align: "center" },
];

export default function Faqs(props) {
  let { type } = useParams();
  var typeId = type === "customer" ? "1" : type === "host" ? "2" : "3";

  const [faqData, setFaqData] = useState([]);
  const [faqTypes, setFaqTypes] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedFaqData, setSelectedFaqData] = useState({});
  const [selectedFaqId, setSelectedFaqId] = useState("");
  const [cfvisible, setCfvisible] = React.useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [openSnackbar] = useSnackbar();

  const getFaqList = () => {
    ApiService({ method: "GET", route: "faqs?user_type=" + typeId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setFaqData(response.data.data);
          setFaqTypes(response.data.options);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getFaqList();
    setIsLoading(true);
    props.onChange(type);
  }, [type]);

  const deleteFaq = () => {
    ApiService({ method: "DELETE", route: "faqs/" + selectedFaqId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          let selectedIndex = faqData.findIndex((p) => p.id == selectedFaqId);
          if (selectedIndex > -1) {
            faqData.splice(selectedIndex, 1);
          }
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <GButton
        variant="linkable"
        children="Add"
        onClick={() => {
          setSelectedFaqData({});
          setIsEmpty(true);
          setCfvisible(true);
        }}
        style={{
          margin: "-60px -15px 0px",
          float: "right",
        }}
      >
        <Add fontSize="large" />
      </GButton>
      {isLoading === true ? (
        <GTableShimmer></GTableShimmer>
      ) : (
        <TableContainer>
          {faqData.length === 0 ? (
            <GEmpty></GEmpty>
          ) : (
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableHeader
                      key={column.id}
                      style={{
                        minWidth: column.minWidth,
                        textAlign: column.align,
                      }}
                    >
                      {column.label}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {faqData.map((c) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                      <TableRowData
                        style={{
                          BorderBottom: "1px solid #f4f5f9",
                        }}
                      >
                        {c.question}
                      </TableRowData>
                      <TableRowData
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <ul
                          style={{
                            display: "inline-flex",
                            color: "#8f8f8f",
                            listStyleType: "none",
                            margin: "0 0 10px",
                            padding: "0px",
                          }}
                        >
                          <li style={{ padding: "0 5px" }}>
                            <ThumbUpAlt
                              fontSize="small"
                              style={{ verticalAlign: "bottom" }}
                            />{" "}
                            {c.likes}
                          </li>
                          <li style={{ padding: "0 5px" }}>
                            <ThumbDownAlt
                              fontSize="small"
                              style={{ verticalAlign: "bottom" }}
                            />
                            {c.dislikes}
                          </li>
                        </ul>
                        <ProgressBar
                          completed={
                            c.likes
                              ? (100 / (c.likes + c.dislikes)) * c.likes
                              : 0
                          }
                          max={c.likes + c.dislikes}
                        />
                      </TableRowData>
                      <TableRowData
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {c.faq_type}
                      </TableRowData>
                      <TableRowData
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <GButton
                          variant="linkable"
                          onClick={(e) => {
                            setSelectedFaqData(c);
                            setIsEmpty(false);
                            setCfvisible(true);
                          }}
                        >
                          <Edit fontSize="small" />
                        </GButton>

                        <GButton
                          variant="linkable"
                          onClick={(e) => {
                            setSelectedFaqId(c.id);
                            setDialogOpen(true);
                          }}
                        >
                          <Delete fontSize="small" />
                        </GButton>
                      </TableRowData>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      )}

      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete FAQ"
          text="Are you sure you want to delete this FAQ?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteFaq();
          }}
        ></GConfirmDialog>
      )}

      <Modal
        isOpen={cfvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCfvisible(false)}
        style={{
          content: {
            width: "400px",
          },
        }}
        contentLabel="FAQ Form Modal"
      >
        <FaqForm
          cfvisible={cfvisible}
          setCfvisible={setCfvisible}
          data={selectedFaqData}
          isEmpty={isEmpty}
          onSubmit={getFaqList}
          userType={typeId}
          faqTypes={faqTypes}
        />
      </Modal>
    </div>
  );
}
