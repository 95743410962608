import React from "react";
// import styled from "styled-components";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { getProductTitle } from "../services";

const useStyles = makeStyles((theme) => ({
  SettingRoute: {
    color: "#000",

    "& a": {
      color: "#000",
      cursor: "pointer",
      display: "block",
      padding: "6px 20px",
      fontSize: "13.5px",
      textDecoration: "none",
      fontWeight: "450",
      letterSpacing: "-0.2px",

      "&:hover": {
        color: "#F8310E",
      },
    },
    "& a.active": {
      color: "#F8310E",
    },
  },
}));
var headerStyle = {
  fontSize: "15px",
  margin: "0px",
  fontWeight: "550",
  padding: "0 20px",
  marginBottom: "10px",
};

var lineStyle = {
  width: "90%",
  marginTop: "10px",
  marginBottom: "15px",
  border: "0px",
  borderTop: "1px solid #eee",
};

export default function SettingRoutes(props) {
  const classes = useStyles();

  return (
    <div className={classes.SettingRoute}>
      <br />
      <h3 style={headerStyle}>General Settings</h3>
      <Link
        to={{ pathname: "/settings/basic", state: "setting" }}
        className={props.url === "/settings/basic" ? "active" : ""}
      >
        Basic Settings
      </Link>
      <Link
        to={{
          pathname: "/settings/social",
          state: "setting",
        }}
        className={props.url === "/settings/social" ? "active" : ""}
      >
        Social Links
      </Link>
      <Link
        to={{
          pathname: "/settings/invoice",
          state: "setting",
        }}
        className={props.url === "/settings/invoice" ? "active" : ""}
      >
        Invoice Settings
      </Link>
      <Link
        to={{
          pathname: "/settings/app",
          state: "setting",
        }}
        className={props.url === "/settings/app" ? "active" : ""}
      >
        App Settings
      </Link>
      <Link
        to={{
          pathname: "/settings/document-types/customer",
          state: "setting",
        }}
        className={props.url === "/settings/document-types" ? "active" : ""}
      >
        Document Types
      </Link>
      <hr style={lineStyle} />

      <h3 style={headerStyle}>Booking Settings</h3>
      <Link
        to={{
          pathname: "/settings/booking-settings",
          state: "setting",
        }}
        className={props.url === "/settings/booking-settings" ? "active" : ""}
      >
        Basic Settings
      </Link>
      <Link
        to={{
          pathname: "/settings/order-notifications/customer",
          state: "setting",
        }}
        className={
          props.url === "/settings/order-notifications" ? "active" : ""
        }
      >
        Notifications{" "}
      </Link>
      <Link
        to={{
          pathname: "/settings/order-cancel-reasons/customer",
          state: "setting",
        }}
        className={
          props.url === "/settings/order-cancel-reasons" ? "active" : ""
        }
      >
        Cancel Reasons{" "}
      </Link>
      <Link
        to={{
          pathname: "/settings/other-settings",
          state: "setting",
        }}
        className={props.url === "/settings/other-settings" ? "active" : ""}
      >
        Other Settings
      </Link>

      <hr style={lineStyle} />

      <h3 style={headerStyle}>Host Settings</h3>
      <Link
        to={{
          pathname: "/settings/roles/host",
          state: "setting",
        }}
        className={props.url === "/settings/roles/host" ? "active" : ""}
      >
        Roles
      </Link>

      <hr style={lineStyle} />

      <h3 style={headerStyle}>{getProductTitle()} Settings</h3>
      <Link
        to={{
          pathname: "/settings/product-groups",
          state: "setting",
        }}
        className={props.url === "/settings/product-groups" ? "active" : ""}
      >
        Restaurant Groups
      </Link>

      <hr style={lineStyle} />

      <h3 style={headerStyle}>Content Settings</h3>
      <Link
        to={{
          pathname: "/settings/banners",
          state: "setting",
        }}
        className={props.url === "/settings/banners" ? "active" : ""}
      >
        Banners{" "}
      </Link>

      <Link
        to={{
          pathname: "/settings/faqs/customer",
          state: "setting",
        }}
        className={props.url === "/settings/faqs" ? "active" : ""}
      >
        FAQs{" "}
      </Link>
      <Link
        to={{
          pathname: "/settings/privacy-policy",
          state: "setting",
        }}
        className={props.url === "/settings/privacy-policy" ? "active" : ""}
      >
        Privacy Policy
      </Link>
      <Link
        to={{
          pathname: "/settings/terms-conditions",
          state: "setting",
        }}
        className={props.url === "/settings/terms-conditions" ? "active" : ""}
      >
        Terms & Conditions
      </Link>
      <hr style={lineStyle} />

      <h3 style={headerStyle}>Website Settings</h3>
      <Link
        to={{ pathname: "/settings/website-settings", state: "setting" }}
        className={props.url === "/settings/website-settings" ? "active" : ""}
      >
        Basic Settings
      </Link>
      <Link
        to={{ pathname: "/settings/web-plugin", state: "setting" }}
        className={props.url === "/settings/web-plugin" ? "active" : ""}
      >
        Homepage Plugins
      </Link>

      <hr style={lineStyle} />

      <h3 style={headerStyle}>Geo-location Settings</h3>

      <Link
        to={{
          pathname: "/settings/cities",
          state: "setting",
        }}
        className={props.url === "/settings/cities" ? "active" : ""}
      >
        Cities
      </Link>
      <hr style={lineStyle} />

      <h3 style={headerStyle}>Notification Settings</h3>
      <Link
        to={{
          pathname: "/settings/email-notifications",
          state: "setting",
        }}
        className={
          props.url === "/settings/email-notifications" ? "active" : ""
        }
      >
        Email
      </Link>
      <Link
        to={{
          pathname: "/settings/sms-notifications",
          state: "setting",
        }}
        className={props.url === "/settings/sms-notifications" ? "active" : ""}
      >
        SMS{" "}
      </Link>
      <Link
        to={{
          pathname: "/settings/sms-otp-notifications",
          state: "setting",
        }}
        className={
          props.url === "/settings/sms-otp-notifications" ? "active" : ""
        }
      >
        SMS OTP
      </Link>

      <hr style={lineStyle} />

      <h3 style={headerStyle}>Staff Settings</h3>

      <Link
        to={{
          pathname: "/settings/roles/admin",
          state: "setting",
        }}
        className={props.url === "/settings/roles/admin" ? "active" : ""}
      >
        Roles
      </Link>
      <Link
        to={{
          pathname: "/settings/staff-members",
          state: "setting",
        }}
        className={props.url === "/settings/staff-members" ? "active" : ""}
      >
        Staff Members
      </Link>
      <br />
    </div>
  );
}
