/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { Add, SubdirectoryArrowRight } from "@material-ui/icons";
import { menus } from "../sidebar/sidebarRoutes";
import settingIcon from "../assets/images/sidebar/settings.svg";

const ParentDiv = styled.div`
  height: calc(90vh - 268px);
  overflow: overlay;

  .pList {
    margin: 0;
  }
  .pListItem {
    font-size: 13px;
    font-weight: 550;
    letter-spacing: 0.2px;
    padding: 6px 15px;
    color: #bbb;
    display: flex;
    margin: 5px 0 0;

    &.active,
    &.active:hover {
      background: #F8310E;
      border-radius: 5px;
      color: #fff;
      span img {
        opacity: 1;
      }
      .cList {
        background: transparent;
      }
      .pLink {
        color: #fff;
        span img {
          opacity: 1;
        }
      }
      .addIcon {
        color: #fff;
      }
    }
    &:hover {
      background: #3a393e;
      border-radius: 5px;
      .cList {
        background: transparent;
      }
      .pLink {
        color: #fff;
      }
    }
    .pListIcon {
      margin-right: 15px;
      vertical-align: middle;
      img {
        width: 15px;
        height: 15px;
        opacity: 0.7;
      }
    }
    .pLink {
      color: #bbb;
      text-decoration: none;
      display: inline-block;
      width: 90%;
      font-weight: 550;
      letter-spacing: -0.2px;
      padding: 2px 0 0 0;
    }
    .addIcon {
      color: #b2b2b2;
      font-size: 17px;
      padding: 0;
      padding-top: 2px;
      cursor: pointer;
    }
  }

  .cList {
    background: transparent;
    list-style-type: none;
    padding: 0 0 2px 10px;
    height: 0px;
    display: none;
    opacity: 0;
    margin: 0;
    &.collapse-in {
      display: block;
      opacity: 1;
      height: auto;
      animation-duration: 0.5s;
      animation-name: slideHight;
      transition: height 0.5s;
    }
    &.collapse {
      display: block;
      opacity: 1;
      height: auto;
      animation-duration: 0.3s;
      animation-name: slideMaxHight;
      transition: height 0.3s;
    }
    .cListItem {
      height: auto;
      padding: 0 10px;
      font-size: 14px;
      &.active,
      &:hover {
        background: transparent;
        .cLink {
          color: #fff;
        }
      }
      .cListIcon {
        vertical-align: middle;
        svg {
          margin-right: 10px;
          float: none;
          padding: 0px;
        }
      }
      .cLink {
        color: #b2b2b2;
        display: block;
        padding: 3px 0;
        font-size: 14px;
        font-weight: 450;
      }
    }
  }
`;

function GNestedMenu(props) {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState({});
  const [isActive, setIsActive] = useState(false);
  const [isShowAddons, setIsShowAddons] = useState(
    location.pathname === "/billing/add-ons" ? true : false
  );
  //console.log(location);

  useEffect(() => {
    let activeKey = "";
    const found = menus.some((el) => el.path === location.pathname);
    // console.log(found);
    if (!found) {
      setIsMenuOpen({});
      setIsActive(false);
    }

    menus.map((s) => {
      s.children &&
        s.children.map((el) => {
          if (s.path === location.pathname) {
            setIsMenuOpen(s);
          }
          if (el.path === location.pathname) {
            activeKey = s;
            setIsMenuOpen(s);
          }
        });
    });
  }, [location]);

  const handleToggle = (data) => {
    setIsMenuOpen(data);
    setIsActive(true);
  };

  function isPermissionExist(per) {
    let exist = props.settings?.permissions?.includes(per);
    console.log(exist);
    return exist;
  }

  function getUserType() {
    let uType =
      props.settings?.user?.user_type === "sa"
        ? true
        : props.settings?.user?.user_type === "admin"
        ? true
        : false;
    return uType;
  }

  const renderMenuItem = (menu, i) => {
    const { title, path, state, icon, children, isLink, isAdd } = menu;

    return (
      <React.Fragment key={i}>
        <li
          className={
            !state
              ? "pListItem"
              : state === location.state
              ? "pListItem active"
              : "pListItem"
          }
          onClick={() => {
            handleToggle(menu, i);
          }}
          href={"#" + menu.path}
        >
          {isLink === true ? (
            <Link className="pLink" to={{ pathname: path, state: state }}>
              <span className="pListIcon">
                <img src={icon} alt={title} />
              </span>{" "}
              {title}
            </Link>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingTop: "2px",
              }}
            >
              <span className="pListIcon">
                {" "}
                <img src={icon} alt={title} />
              </span>
              <span style={{ fontWeight: "550", letterSpacing: "0px" }}>
                {" "}
                &nbsp;{title}
              </span>
            </div>
          )}
          {isAdd &&
          (getUserType() == true ? true : isPermissionExist(isAdd)) === true ? (
            <span>
              {
                <Add
                  className="addIcon"
                  onClick={() => props.handleClick(title)}
                />
              }
            </span>
          ) : (
            ""
          )}
        </li>
        {children && children.length > 0 ? renderSubMenuItem(menu) : ""}
      </React.Fragment>
    );
  };

  const renderSubMenuItem = (menu) => {
    const { children } = menu;

    return (
      <ul
        key={menu.path}
        className={`cList ${
          isMenuOpen === menu ? (menu.path ? "collapse-in" : "collapse") : ""
        }`}
        id={menu.path}
      >
        {children &&
          children.map((child, i) => {
            return (
              <li
                className={
                  child.path === location.pathname
                    ? "cListItem active"
                    : "cListItem"
                }
                key={i}
              >
                <Link
                  className="cLink"
                  to={{ pathname: child.path, state: child.state }}
                >
                  <span className="cListIcon">
                    <SubdirectoryArrowRight />
                  </span>
                  {child.title}
                </Link>
              </li>
            );
          })}
      </ul>
    );
  };

  return (
    <React.Fragment>
      <ParentDiv
        mode="inline"
        theme="dark"
      >
        <ul className="pList">
          {getUserType() ? (
            <>
              {menus
                .filter((f) => f.title)
                .map((menu, i) => renderMenuItem(menu, i))}
            </>
          ) : (
            <>
              {menus
                .filter((m) => isPermissionExist(m.isStaff) === true)
                .map((menu, i) => renderMenuItem(menu, i))}
            </>
          )}
        </ul>
      </ParentDiv>

      {props.settings?.user?.user_type === "staff" ? (
        ""
      ) : (
        <>
          <h4>Other Information</h4>
          <ul className="pList">
               <li className={location.state === "setting" ? "active" : ""}>
              <Link to={{ pathname: "/settings/basic", state: "setting" }}>
                <span role="img" aria-label="Order" className="icon">
                  {/* 🛠️ */}
                  <img src={settingIcon} alt="Settings" />
                </span>
                <span>Settings</span>
              </Link>
            </li>
          </ul>
        </>
      )}
    </React.Fragment>
  );
}

export default GNestedMenu;
