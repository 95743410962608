import React, { useState } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";
import { HostFilter } from "../components/hostFilterComponent";
import { ProductFilter } from "./productFilterComponent";

const notifyTypes = [
  {
    title: "All",
    value: "all",
  },
  {
    title: "Ordered from Any restaurant in Last X days",
    value: "ordered_in_days",
  },
  {
    title: "Ordered from Selected restaurant in Last X days",
    value: "ordered_restaurant_in_days",
  },
];

export default function BulkNotifications() {
  const [openSnackbar] = useSnackbar();
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);

  const initialValues = {
    title: "",
    subtitle: "",
    type: "",
    host_id: "",
    restaurant_id: "",
    days: "",
  };

  const onSubmit = (values, { setFieldValue, resetForm }) => {
    console.log(values);
    setIsBtnDisabled(true);

    ApiService({
      method: "POST",
      route: "promotion/push",
      body: values,
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        setFieldValue("title", "");
        setFieldValue("subtitle", "");
        resetForm(initialValues);
      }
      setIsBtnDisabled(false);
      openSnackbar(response.data.message);
      return;
    });
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Required"),
    subtitle: Yup.string().required("Required"),
    type: Yup.string().required("Required"),
    days: Yup.string().when("type", {
      is: (type) =>
        type == "ordered_in_days" || type == "ordered_restaurant_in_days",
      then: Yup.string().required("Required"),
    }),
    host_id: Yup.string().when("type", {
      is: "ordered_restaurant_in_days",
      then: Yup.string().required("Required"),
    }),
    restaurant_id: Yup.string().when("type", {
      is: "ordered_restaurant_in_days",
      then: Yup.string().required("Required"),
    }),
  });

  return (
    <div className="mainInnerView">
      <h1>Bulk Notifications</h1>
      <div className="listPaper">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form style={{ width: "500px" }}>
              <FormikControl
                control="input"
                type="text"
                label="Title"
                name="title"
              />
              <FormikControl
                control="input"
                as="textarea"
                type="text"
                label="Subtitle"
                name="subtitle"
                rows="5"
              />

              <FormikControl
                control="select"
                as="select"
                label="Choose Customers Selection"
                name="type"
                options={notifyTypes}
                key_title="title"
                key_value="value"
              />

              {values.type === "ordered_restaurant_in_days" ? (
                <div style={{ padding: "4px 15px 8px" }}>
                  <label>Hosts</label>
                  <HostFilter
                    value={values.host_id || ""}
                    handleHostChange={(value) => {
                      console.log(value);
                      setFieldValue("host_id", value || "");
                      if (!value) {
                        setFieldValue("restaurant_id", "");
                      }
                    }}
                    style={{ width: "100%", minWidth: "auto" }}
                  />
                  <FormikControl
                    control="input"
                    type="text"
                    name="host_id"
                    style={{ display: "none" }}
                    divstyle={{ margin: 0, padding: 0 }}
                  />

                  {values.host_id && (
                    <div style={{ padding: "10px 0 0" }}>
                      <label>Restaurants</label>
                      <ProductFilter
                        value={values.restaurant_id || ""}
                        style={{
                          minWidth: "250px",
                          margin: 0,
                          width: "100%",
                        }}
                        hostId={values.host_id}
                        handleProductChange={(id, value) => {
                          setFieldValue("restaurant_id", id || "");
                        }}
                      />
                      <FormikControl
                        control="input"
                        type="text"
                        name="restaurant_id"
                        style={{ display: "none" }}
                        divstyle={{ margin: 0, padding: 0 }}
                      />
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}

              {values.type === "ordered_restaurant_in_days" ||
              values.type === "ordered_in_days" ? (
                <>
                  <FormikControl
                    control="numberInput"
                    type="text"
                    label="Days"
                    name="days"
                    maxLength={5}
                    onChange={(event) => {
                      const re = /^[0-9\b]+$/;
                      if (event.target.value && !re.test(event.target.value)) {
                        return;
                      } else {
                        setFieldValue("days", event.target.value);
                      }
                    }}
                  />
                </>
              ) : (
                ""
              )}

              <GButton
                type="submit"
                disabled={isBtnDisabled}
                variant="condensed"
                children="Send Notification"
                style={{ display: "flex", margin: "15px 15px 20px auto" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
