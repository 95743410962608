import React, { useState, useEffect } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";
import GText from "../gComponents/gText";
import GEmpty from "../gComponents/gEmpty";

export default function AssignRoleForm(props) {
  const [openSnackbar] = useSnackbar();
  const [isDisabled, setIsDisabled] = useState(false);
  const [rolesData, setRolesData] = useState([]);

  const initialValues = {
    role_id: props.staffData?.role?.id || "",
  };

  useEffect(() => {
    ApiService({ method: "GET", route: "roles?guard_name=" + props.type })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setRolesData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const onSubmit = (values) => {
    setIsDisabled(true);
    console.log(values);
    if (props.type === "host") {
      values.host_id = props.staffData.id;
    } else {
      values.admin_id = props.staffData.id;
    }
    const apiUrl = props.type == "host" ? "host-role/assign" : "role/assign";
    // return;
    ApiService({ method: "PUT", route: apiUrl, body: values }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code == 1) {
          props.setAssignRoleVisible(false);
          props.onSubmit(response.data);
        }
        setIsDisabled(false);
        openSnackbar(response.data.message);
      }
    );
  };

  const validationSchema = Yup.object({
    role_id: Yup.string().required("Required"),
  });

  if (props.assignRoleVisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">Assign Role</div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              {rolesData.length > 0 ? (
                <>
                  <FormikControl
                    control="radio"
                    label="Choose a Role"
                    name="role_id"
                    options={rolesData}
                    key_title="name"
                    key_value="id"
                  />
                  <br />
                  <GButton
                    variant="condensed"
                    disabled={isDisabled}
                    children={"Assign"}
                    type="submit"
                    style={{ margin: "0 15px", width: "calc(100% - 30px)" }}
                  />
                </>
              ) : (
                // <GEmpty subtitle={"No roles have been added yet"} />
                <GText
                  med
                  text={"No roles have been added yet. "}
                  style={{ padding: "10px 15px" }}
                />
              )}
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
