import React, { useEffect, useState } from "react";
import {
  ApiService,
  addDefaultSrc,
  ImgUrl,
  getProductTitle,
} from "../services";
import { Slider } from "@material-ui/core";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GImageEditor from "../gComponents/gImageEditor";
import GButton from "../gComponents/gButton";
import GText from "../gComponents/gText";
import { Delete } from "@material-ui/icons";
import ProductImages from "../products/productImages";
import { values } from "lodash";
import GMap from "../gComponents/gMaps";
import GTabs from "../gComponents/gTabs";
import ProductUnits from "../products/productUnits";
import useDataFactory from "../useDataFactory";
import GTagsInput from "../gComponents/gTagsInput";
import styled from "styled-components";

const Div = styled.div`
.MuiSlider-root {
    color: #F8310E;
    `;

export default function ProductEditForm(props) {
  const productTypes = ["basic_info", "images", props.settings?.unit_display];
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [openSnackbar] = useSnackbar();
  const [productImages, setProductImgaes] = useState(props.data.images || []);
  const { data: groupsData } = useDataFactory("product_groups", false);

  const [typeValue, setTypeValue] = useState(productTypes[0]);

  const initialValues = props.fields.reduce(
    (a, v) => ({
      ...a,
      [v?.id]: v?.value
        ? v.value
        : v?.type == "checkbox"
        ? v.value == 1
          ? 1
          : 0
        : "",
    }),
    {
      address: props.data?.address || "",
      price_from: props.data?.price_from || 0,
      price_upto: props.data?.price_upto || 30,
      lat: props.data?.lat || "",
      lng: props.data?.lng || "",
      units: props.data?.units || "",
      min_users: props.data?.min_users || "",
      max_users: props.data?.max_users || "",
      users_covered: props.data?.users_covered || 0,
      user_cost: props.data?.user_cost || "",
      booking_reminder: props.data.booking_reminder || 0,
      featured: props.data.featured || 0,
      booking_sms: props.data.booking_sms,
      booking_sms_delay: props.data.booking_sms_delay,
      groups: props.data.groups || [],
    }
  );

  function salaryValueLabelFormat(value) {
    return `${Math.round(value)}`;
  }
  const onSubmit = (values) => {
    setIsBtnDisabled(true);
    console.log(values);

    let sendData = {
      user_cost: values.user_cost,
      users_covered: values.users_covered,
      custom_fields: values,
      address: values.address,
      lat: values.lat,
      lng: values.lng,
      price_from: values.price_from || 0,
      price_upto: values.price_upto || 30,
      units: values.units,
      min_users: values.min_users,
      max_users: values.max_users,
      booking_reminder: values.booking_reminder,
      featured: values.featured,
      booking_sms: values.booking_sms,
      booking_sms_delay: values.booking_sms_delay || 0,
      groups: values.groups || [],
    };
    ApiService({
      method: "PUT",
      route: "products/" + props.data.id,
      body: sendData,
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        props.setPevisible(false);
        props.onSubmit();
      }
      setIsBtnDisabled(false);
      openSnackbar(response.data.message);
    });
  };

  const validationSchema = Yup.object({
    address: Yup.string().required(),
  }).shape(
    props.fields
      .filter((t) => t.required)
      .reduce(
        (a, v) => ({
          ...a,
          [(v?.id).toString()]:
            v.type == "checkbox"
              ? ""
              : Yup.string()
                  .nullable()
                  .required(v.title + " is required"),
        }),
        {}
      )
  );

  if (props.pevisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header"> Edit {getProductTitle()}</div>

      <div
        className="modal-body"
        style={{ padding: "10px 0", overflow: "hidden" }}
      >
        <GTabs
          routes={productTypes}
          active={typeValue}
          type="single"
          onClick={(newVal) => setTypeValue(newVal)}
          style={{ borderBottom: "1px solid #e2e2e2" }}
        ></GTabs>

        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              {typeValue == "basic_info" && (
                <div
                  style={{
                    overflow: "overlay",
                    height: "calc(100vh - 160px)",
                    padding: "20px",
                  }}
                >
                  <GMap
                    centerLocation={{
                      lat: values?.lat ? parseFloat(values.lat) : 30.7046,
                      lng: values?.lng ? parseFloat(values.lng) : 76.7179,
                    }}
                    search={true}
                    height="30vh"
                    type="createStore"
                    onChange={(address, loc) => {
                      values.lat = loc.lat();
                      values.lng = loc.lng();
                      setFieldValue("address", address.address);
                    }}
                    label="Location"
                    padding={true}
                  />
                  <FormikControl
                    control="input"
                    type="text"
                    label="Address"
                    name="address"
                  />

                  <Div
                    style={{
                      padding: "6px 15px",
                    }}
                  >
                    <label>
                      Price (
                      {props.settings.currency_symbol + values.price_from} -{" "}
                      {props.settings.currency_symbol + values.price_upto})
                    </label>
                    <Slider
                      min={0}
                      max={100}
                      step={1}
                      defaultValue={[
                        values.price_from || 0,
                        values.price_upto || 30,
                      ]}
                      onChange={(e, val) => {
                        console.log(e);
                        console.log(val);
                        setFieldValue("price_from", val[0]);
                        setFieldValue("price_upto", val[1]);
                      }}
                      valueLabelDisplay="auto"
                      aria-labelledby="range-slider"
                      getAriaValueText={salaryValueLabelFormat}
                      valueLabelFormat={salaryValueLabelFormat}
                    />
                  </Div>
                  {props.fields.map((f, i) => (
                    <React.Fragment key={i}>
                      {f.type == "select" && (
                        <FormikControl
                          control="selectOption"
                          as="select"
                          label={f.title}
                          name={f.id.toString()}
                          options={f.answers}
                          key_title="title"
                          key_value="id"
                          divstyle={{
                            display: "inline-block",
                            width: "50%",
                            boxSizing: "border-box",
                            verticalAlign: "top",
                          }}
                        />
                      )}
                      {f.type == "text" && (
                        <FormikControl
                          control="input"
                          type="text"
                          label={f.title}
                          name={f.id}
                        />
                      )}
                      {f.type == "textarea" && (
                        <FormikControl
                          control="input"
                          as="textarea"
                          label={f.title}
                          name={f.id}
                        />
                      )}

                      {f.type == "checkbox" && (
                        <FormikControl
                          control="singleCheckbox"
                          as="checkbox"
                          label={f.title}
                          name={f.id}
                          checked={values[f.id] == 1 ? true : false}
                          onChange={(e) => {
                            values[f.id] = e.target.checked == true ? 1 : 0;
                            setFieldValue(
                              f.id,
                              e.target.checked == true ? 1 : 0
                            );
                          }}
                        />
                      )}
                    </React.Fragment>
                  ))}

                  <div style={{ margin: "15px 15px 25px" }}>
                    <label>Groups</label>
                    <GTagsInput
                      selectedData={values.groups}
                      data={groupsData}
                      placeholder={"Select Groups"}
                      onSubmit={(data) => {
                        console.log(data);
                        values.groups = data;
                        setFieldValue("groups", data);
                      }}
                      showButton={false}
                    />
                  </div>

                  <FormikControl
                    control="toggle"
                    label={"Allow Notification Reminders"}
                    isToggled={values.booking_reminder}
                    name="booking_reminder"
                    onToggle={() => {
                      setFieldValue(
                        "booking_reminder",
                        values.booking_reminder == 1 ? 0 : 1
                      );
                    }}
                    style={{
                      display: "inline-block",
                      width: "50%",
                      boxSizing: "border-box",
                      verticalAlign: "top",
                    }}
                  />

                  <FormikControl
                    control="toggle"
                    label={"Featured"}
                    isToggled={values.featured}
                    name="featured"
                    onToggle={() => {
                      setFieldValue("featured", values.featured == 1 ? 0 : 1);
                    }}
                    style={{
                      display: "inline-block",
                      width: "50%",
                      boxSizing: "border-box",
                      verticalAlign: "top",
                    }}
                  />

                  <FormikControl
                    control="input"
                    as="textarea"
                    label={"Booking SMS"}
                    name="booking_sms"
                  />

                  <FormikControl
                    control="input"
                    type="text"
                    label={"Booking SMS Delay (in mins)"}
                    name={"booking_sms_delay"}
                    onChange={(event) => {
                      const re = /^[0-9\b]+$/;
                      if (event.target.value && !re.test(event.target.value)) {
                        return;
                      } else {
                        setFieldValue("booking_sms_delay", event.target.value);
                      }
                    }}
                    divstyle={{
                      display: "inline-block",
                      width: "50%",
                      boxSizing: "border-box",
                      verticalAlign: "top",
                    }}
                  />
                  <GButton
                    type="submit"
                    disabled={isBtnDisabled}
                    variant="condensed"
                    children="Update"
                    style={{ width: "calc(100% - 30px)", margin: "15px" }}
                  />
                </div>
              )}
            </Form>
          )}
        </Formik>

        {typeValue == "images" && (
          <div
            style={{
              overflow: "overlay",
              height: "calc(100vh - 160px)",
              padding: "20px",
            }}
          >
            {/* <GText semi g2 text={getProductTitle() + " Images"} /> */}
            <div style={{ margin: "15px" }}>
              <ProductImages
                onUpload={(data) => {
                  setProductImgaes([...productImages, data]);
                }}
                onRemove={(id) => {
                  setProductImgaes(
                    productImages.filter((item) => item.id !== id)
                  );
                }}
                productImages={productImages}
                add={true}
                productId={props.data.id}
              />
            </div>
          </div>
        )}

        {typeValue == props.settings?.unit_display && (
          <div
            style={{
              overflow: "overlay",
              height: "calc(100vh - 160px)",
              padding: "20px",
            }}
          >
            <ProductUnits productId={props.data?.id} />
          </div>
        )}
      </div>
    </>
  );
}
