import React, { useEffect, useState } from "react";
import { ApiService } from "../services";
import { Add, Delete } from "@material-ui/icons";
import GButton from "../gComponents/gButton";
import { Formik, Form, Field, FieldArray } from "formik";
import FormikControl from "../formikComponents/formikControl";
import * as Yup from "yup";
import { useSnackbar } from "react-simple-snackbar";

const fieldStyle = {
  border: "0px",
  borderBottom: "1px solid #d2d2d2",
  background: "transparent",
};
export default function ProductUnitForm(props) {
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [openSnackbar] = useSnackbar();
  const [selectedProductUnit, setSelectedProductUnit] = useState(
    props.data || []
  );

  const validationSchema = Yup.object({});

  useEffect(() => {
    setSelectedProductUnit(props.data || []);
  }, [props]);

  const initialValues = {
    options: props.data,
  };

  const handleUpdateUnit = (values, ind) => {
    ApiService({
      method: "PUT",
      route: "product/units/" + values.options[ind].id,
      body: values.options[ind],
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
      } else {
        setSelectedProductUnit(props.data);
      }
      openSnackbar(response.data.message);
      return;
    });
  };

  return (
    <div style={props.style}>
      <Formik
        initialValues={initialValues}
        //onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ setFieldValue }) => (
          <Form>
            <FieldArray name="options">
              {(fieldArrayProps) => {
                const { push, remove, form } = fieldArrayProps;
                const { values } = form;
                const { options } = values;
                const handleAddUnit = (ind) => {
                  setIsBtnDisabled(true);
                  values.options[ind].product_id = props.productId;
                  ApiService({
                    method: "POST",
                    route: "product/units",
                    body: values.options[ind],
                  }).then((response) => {
                    console.log(response.data);
                    if (response.data.status_code === 1) {
                      setFieldValue(`options[${ind}]`, response.data.data);
                      props.onVariantAdd(response.data.data);
                    } else {
                      setSelectedProductUnit(props.data || []);
                    }
                    setIsBtnDisabled(false);
                    openSnackbar(response.data.message);
                    return;
                  });
                };
                const handleDeleteUnit = (ind) => {
                  ApiService({
                    method: "DELETE",
                    route: "product/units/" + values.options[ind].id,
                  }).then((response) => {
                    console.log(response.data);
                    if (response.data.status_code === 1) {
                      props.onVariantRemove(ind);
                      remove(ind);
                    }
                    openSnackbar(response.data.message);
                    return;
                  });
                };

                return (
                  <div>
                    <div
                      style={{
                        display: "inline-flex",
                        width: "100%",
                        padding: "0px 0 0 20px",
                        boxSizing: "border-box",
                      }}
                      className="arrayFields"
                    >
                      <label
                        style={{
                          width: "30%",
                        }}
                      >
                        Title
                      </label>

                      <label
                        style={{
                          width: "20%",
                        }}
                      >
                        Min Users
                      </label>

                      <label
                        style={{
                          width: "20%",
                        }}
                      >
                        Max Users
                      </label>

                      <GButton
                        variant="linkable"
                        onClick={(e) => {
                          push({
                            title: "",
                            min_users: "0",
                            max_users: "0",
                          });
                        }}
                      >
                        <Add />
                      </GButton>
                    </div>
                    {options.map((o, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            display: "inline-flex",
                            width: "100%",
                            padding: "0px 0 0 15px",
                            boxSizing: "border-box",
                          }}
                          className="arrayFields"
                        >
                          <Field
                            type="text"
                            placeholder=""
                            name={`options[${index}].title`}
                            style={Object.assign({}, fieldStyle, {
                              width: "25%",
                            })}
                          />

                          <Field
                            type="text"
                            placeholder=""
                            name={`options[${index}].min_users`}
                            style={Object.assign({}, fieldStyle, {
                              width: "20%",
                            })}
                          />

                          <Field
                            type="text"
                            placeholder=""
                            name={`options[${index}].max_users`}
                            style={Object.assign({}, fieldStyle, {
                              width: "20%",
                            })}
                          />

                          {!options[index].id ? (
                            <GButton
                              disabled={isBtnDisabled}
                              variant="linkable"
                              onClick={(e) => {
                                handleAddUnit(index);
                              }}
                              style={{ width: "150px" }}
                            >
                              Add
                            </GButton>
                          ) : (
                            <span style={{ display: "inline-flex" }}>
                              <GButton
                                variant="linkable"
                                onClick={(e) => {
                                  handleUpdateUnit(values, index);
                                }}
                              >
                                Update
                              </GButton>
                              <GButton
                                variant="linkable"
                                onClick={(e) => {
                                  handleDeleteUnit(index);
                                }}
                              >
                                <Delete />
                              </GButton>
                            </span>
                          )}
                        </div>
                      );
                    })}
                  </div>
                );
              }}
            </FieldArray>
          </Form>
        )}
      </Formik>
    </div>
  );
}
