import React, { useState, useEffect } from "react";
import { TableContainer } from "@material-ui/core";
import {
  ApiService,
  getUserType,
  isPermissionExist,
  getProductTitleIdent,
  getProductTitle,
} from "../services";
import { Assignment } from "@material-ui/icons";
import { Link, useHistory } from "react-router-dom";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import GPagination from "../gComponents/gPagination";
import GPaginationSelect from "../gComponents/gPaginationSelectbox";
import GTableShimmer from "../gComponents/gTableShimmer";
import GEmpty from "../gComponents/gEmpty";
import QueryWith from "../Query";
import OrderStatuses from "../gComponents/gOrderStatus";
import { HostFilter } from "../components/hostMultiFilterComponent";
import { CustomerFilter } from "../components/customerMultiFilterComponent";
import GSorting from "../gComponents/gSorting";
import useSettings from "../useSettings";
import { createSocketConnection } from "../useLaravelPusher";
import { ItemFilter } from "../components/itemFilterComponent";

const orderSorting = [
  {
    title: "Created At",
    value: "created_at",
  },
  {
    title: "Scheduled Time",
    value: "starts",
  },
];

export default function Bookings(props) {
  const [ordersData, setOrderData] = useState([]);
  const [orderPaginationData, setOrderPaginationData] = useState("");
  const [orderStatusData, setOrderStatusData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let history = useHistory();
  let params = new URLSearchParams(history.location.search);
  const [filters, setFilters] = useState({
    page: params.get("page") ? params.get("page") : "1",
    status: params.get("status") ? params.get("status") : "active",
    hosts: params.get("hosts") ? params.get("hosts") : "",
    product_id: params.get("product_id") ? params.get("product_id") : "",
    customers: params.get("customers") ? params.get("customers") : "",
    orderby: params.get("orderby") ? params.get("orderby") : "created_at",
    order: params.get("order") ? params.get("order") : "DESC",
  });

  const settings = useSettings(true);

  const columns = [
    { index: 1, id: "id", label: "Id", minWidth: 30, type: "order" },
    {
      index: 2,
      id: "customer",
      label: "Customer",
      minWidth: 100,
      type: "customer_id",
      align: "center",
    },
    {
      index: 3,
      id: "host",
      label: "Host",
      minWidth: 150,
      type: "host_id",
      align: "center",
    },
    {
      index: 4,
      id: getProductTitleIdent(),
      label: getProductTitle(),
      minWidth: 150,
      type: "order",
      align: "center",
    },
    {
      index: 5,
      id: "status",
      label: "Status",
      minWidth: 120,
      type: "order",
      align: "center",
    },
    {
      index: 6,
      id: "created_at_formatted",
      label: "Created",
      minWidth: 130,
      type: "order",
      align: "center",
    },
  ];

  useEffect(() => {
    if (props.type) {
      if (props.onChange) {
        props.onChange("bookings");
      }
      var obj = {};
      obj["page"] = filters.page;
      obj[props.title] = props.value;
    } else {
      var params = QueryWith(history.location, filters);
      if (params.length > 0) {
        history.push({
          pathname: "/bookings",
          state: "booking",
          search: params[0],
        });
      }
    }
    var query = props.type ? obj : filters;
    ApiService({ method: "GET", route: "orders", body: query })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setOrderData(response.data.data.data);
          setOrderPaginationData(response.data.data);
          setOrderStatusData(response.data.statuses);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [history, filters, props.value]);

  const handleChangePage = (pageUrl) => {
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setFilters({ ...filters, page: NewPageNo });
  };

  useEffect(() => {
    createSocketConnection();
    window.Echo.channel("admin-updates").listen("BCOrderUpdated", function (e) {
      console.log(e);
      let findInd = ordersData.findIndex((o) => o.id == e.order?.id);
      if (findInd > -1) {
        ordersData[findInd] = e.order;
        setOrderData([...ordersData] || []);
      }
    });

    return () => {
      window.Echo.leaveChannel(`admin-updates`);
    };
  }, [orderStatusData]);

  return (
    <div className={props.type ? "" : "mainInnerView"}>
      {!props.type ? (
        <React.Fragment>
          <h1>
            <Assignment /> Bookings
          </h1>
        </React.Fragment>
      ) : (
        ""
      )}

      {!props.type ? (
        <React.Fragment>
          <OrderStatuses
            statusList={orderStatusData}
            onClickStatus={(oStatus) => {
              setFilters({ ...filters, page: "1", status: oStatus });
            }}
            defaultStatus={filters.status}
          />

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "initial",
              gap: "7px",
              // width: "calc(100% - 300px)",
            }}
          >
            {getUserType() || isPermissionExist("host_index") ? (
              <HostFilter
                value={filters.hosts ? filters.hosts.split(",") : []}
                handleHostChange={(values) => {
                  setFilters({
                    ...filters,
                    page: 1,
                    hosts: values.length > 0 ? values.toString() : "",
                  });
                }}
                style={{ width: "auto", minWidth: "auto" }}
              />
            ) : (
              ""
            )}
            {getUserType() || isPermissionExist("customer_index") ? (
              <CustomerFilter
                cvalue={filters.customers ? filters.customers.split(",") : []}
                handleCustomerChange={(values) => {
                  setFilters({
                    ...filters,
                    page: 1,
                    customers: values.length > 0 ? values.toString() : "",
                  });
                }}
                style={{ width: "auto", minWidth: "auto" }}
              />
            ) : (
              ""
            )}

            {getUserType() || isPermissionExist("product_index") ? (
              <ItemFilter
                value={filters.product_id || ""}
                handleItemChange={(values) => {
                  setFilters({
                    ...filters,
                    page: 1,
                    product_id: values || "",
                  });
                }}
                style={{ width: "auto", minWidth: "auto" }}
              />
            ) : (
              ""
            )}

            <GSorting
              defaultvalue={filters.orderby}
              defaultOrdervalue={filters.order}
              data={orderSorting}
              id="sortBy"
              option_key="title"
              option_value="value"
              onChange={(newVal) => {
                console.log(newVal);
                setFilters({ ...filters, page: "1", orderby: newVal });
              }}
              style={{
                height: "40px",
                minWidth: "150px",
              }}
              onOrderChange={(newVal) => {
                console.log(newVal);
                setFilters({ ...filters, page: "1", order: newVal });
              }}
            />

            {isLoading == false && ordersData.length > 0 ? (
              <>
                <GPagination
                  style={{ display: "inline-block", marginLeft: "auto" }}
                  count={orderPaginationData.total}
                  from={orderPaginationData.from}
                  to={orderPaginationData.to}
                  onChangeNextPage={() =>
                    handleChangePage(orderPaginationData.next_page_url)
                  }
                  onChangePrevPage={() =>
                    handleChangePage(orderPaginationData.prev_page_url)
                  }
                  rowsPerPage={orderPaginationData.per_page}
                  prevDisabled={
                    orderPaginationData.prev_page_url ? false : true
                  }
                  nextDisabled={
                    orderPaginationData.next_page_url ? false : true
                  }
                ></GPagination>
                <GPaginationSelect
                  defaultvalue={filters.page}
                  totalPage={orderPaginationData.last_page}
                  onChangePage={(NewPageNo) =>
                    setFilters({ ...filters, page: "1", page: NewPageNo })
                  }
                />
              </>
            ) : (
              ""
            )}
          </div>
        </React.Fragment>
      ) : (
        ""
      )}

      <div className="listPaper" style={{ marginTop: props.type ? "" : "5px" }}>
        {isLoading == false && ordersData.length !== 0 && props.type ? (
          <GPagination
            count={orderPaginationData.total}
            from={orderPaginationData.from}
            to={orderPaginationData.to}
            onChangeNextPage={() =>
              handleChangePage(orderPaginationData.next_page_url)
            }
            onChangePrevPage={() =>
              handleChangePage(orderPaginationData.prev_page_url)
            }
            rowsPerPage={orderPaginationData.per_page}
            prevDisabled={orderPaginationData.prev_page_url ? false : true}
            nextDisabled={orderPaginationData.next_page_url ? false : true}
          ></GPagination>
        ) : (
          ""
        )}

        {isLoading == true ? (
          <GTableShimmer></GTableShimmer>
        ) : (
          <TableContainer>
            {ordersData.length === 0 ? (
              <GEmpty></GEmpty>
            ) : (
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns
                      .filter((column) => column.type !== props.title)
                      .map((c) => {
                        return (
                          <TableHeader
                            key={c.id}
                            style={{
                              minWidth: c.minWidth,
                              maxWidth: c.maxWidth,
                              width: c.width,
                              textAlign: c.align,
                            }}
                          >
                            {c.label}
                          </TableHeader>
                        );
                      })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ordersData.map((s) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={s.id}>
                        <TableRowData>
                          <Link
                            to={{
                              pathname: "/booking/" + s.id,
                              state: "booking",
                            }}
                          >
                            #{s.id}
                          </Link>
                        </TableRowData>
                        {props.title !== "customer_id" && (
                          <TableRowData style={{ textAlign: "center" }}>
                            {s.customer ? (
                              <Link
                                to={{
                                  pathname:
                                    "/customer/" + s.customer.id + "/bookings",
                                  state: "customer",
                                }}
                              >
                                {s.customer.title}
                              </Link>
                            ) : (
                              "-"
                            )}
                          </TableRowData>
                        )}
                        {props.title !== "host_id" && (
                          <TableRowData style={{ textAlign: "center" }}>
                            {s.host?.id ? (
                              <Link
                                to={{
                                  pathname: "/host/" + s.host?.id + "/bookings",
                                  state: "host",
                                }}
                              >
                                {s.host?.title}
                              </Link>
                            ) : (
                              ""
                            )}
                          </TableRowData>
                        )}
                        <TableRowData style={{ textAlign: "center" }}>
                          {s.product_title || "-"}
                        </TableRowData>
                        <TableRowData style={{ textAlign: "center" }}>
                          <span
                            className="orderStatus"
                            style={{
                              color: "#" + s.status_display?.color1,
                              background: "#" + s.status_display?.color2,
                            }}
                          >
                            {s.status_display?.title}
                          </span>
                        </TableRowData>
                        <TableRowData style={{ textAlign: "center" }}>
                          {s.created}
                        </TableRowData>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        )}
      </div>
    </div>
  );
}
