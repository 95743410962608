import React, { useState } from "react";
import GMap from "../gComponents/gMaps";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";

const addressTitle = [
  {
    key: "HOME",
    value: "home",
  },
  {
    key: "OFFICE",
    value: "office",
  },
  {
    key: "OTHER",
    value: "other",
  },
];

export default function AddressForm(props) {
  const [openSnackbar] = useSnackbar();
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [addressLocation, setAddressLocation] = useState(
    !props.isEmpty
      ? {
          lat: parseFloat(props.data.latitude),
          lng: parseFloat(props.data.longitude),
        }
      : {
          lat: process.env.REACT_APP_DEFAULT_LATITUDE,
          lng: process.env.REACT_APP_DEFAULT_LONGITUDE,
        }
  );
  const initialValues = {
    type: "saved",
    customer_id: props.id,
    title: !props.isEmpty
      ? props.data.title
        ? addressTitle.filter((at) => at.value == props.data.title).length > 0
          ? props.data.title
          : "other"
        : "home"
      : "home",
    other_title: !props.isEmpty
      ? props.data.title
        ? addressTitle.filter((at) => at.value == props.data.title).length > 0
          ? ""
          : props.data.title
        : props.data.title
      : "",
    line1: !props.isEmpty ? props.data.line1 : "",
    line2: !props.isEmpty ? props.data.line2 : "",
    phone: !props.isEmpty ? props.data.phone : "",
    latitude: !props.isEmpty ? props.data.latitude : "",
    longitude: !props.isEmpty ? props.data.longitude : "",
    city: !props.isEmpty ? props.data.city : "",
    state: !props.isEmpty ? props.data.state : "",
    country: !props.isEmpty ? props.data.country : "",
    zipcode: !props.isEmpty ? props.data.zipcode : "",
  };

  const onSubmit = (values) => {
    setIsBtnDisabled(true);
    let sendValues = { ...values };
    sendValues.title =
      sendValues.title == "other" ? sendValues.other_title : sendValues.title;
    console.log(sendValues);
    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty ? "addresses/" + props.data.id : "addresses";
    ApiService({ method: hitMethod, route: hitUrl, body: sendValues }).then(
      (response) => {
        console.log(response.data);
        openSnackbar(response.data.message);
        if (response.data.status_code === 1) {
          props.onSubmit();
          props.setAfvisible(false);
        }
        setIsBtnDisabled(false);
      }
    );
  };

  const validationSchema = Yup.object({
    line1: Yup.string().required("Required"),
  });

  if (props.afvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        {props.isEmpty === true ? "Create" : "Edit"} Address
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <GMap
                centerLocation={addressLocation}
                search={true}
                height="30vh"
                type="createAddress"
                onChange={(address, loc) => {
                  values.latitude = loc.lat();
                  values.longitude = loc.lng();
                  values.line1 = address.address;
                  values.city = address.city;
                  values.state = address.state;
                  values.country = address.country;
                  values.zipcode = address.zipcode;
                  setFieldValue("line1", address.address);
                }}
                padding={true}
              />

              <FormikControl
                control="radio"
                name="title"
                options={addressTitle}
                key_title="key"
                selected={values.title}
                key_value="value"
                icons={true}
                style={{ display: "none" }}
                divstyle={{
                  display: "inline-block",
                  width: "33%",
                  border: "1px solid #d2d2d2",
                  textAlign: "center",
                  padding: "5px",
                  boxSizing: "border-box",
                  cursor: "pointer",
                }}
                labelStyle={{
                  width: "100%",
                  boxSizing: "border-box",
                  margin: "3px 0",
                }}
              />

              {values.title === "other" && (
                <FormikControl
                  control="input"
                  type="text"
                  label={"Save as (Ex: Farmhouse, Danny's Home)"}
                  name="other_title"
                />
              )}
              <FormikControl
                control="input"
                type="text"
                label="HOUSE / FLAT / BLOCK NO.*"
                name="line1"
              />
              <FormikControl
                control="input"
                type="text"
                label="LANDMARK / EXTRA DETAILS"
                name="line2"
              />
              <br />
              <GButton
                disabled={isBtnDisabled}
                variant="condensed"
                children={!props.isEmpty ? "Update" : "Create"}
                type="submit"
                style={{ width: "calc(100% - 30px)", margin: "0 15px" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
