import React, { Component, useRef, useEffect, useState } from "react";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import Pickup from "../assets/images/stop-pickup.svg";
import Dropoff from "../assets/images/stop-dropoff.svg";
import driverIcon from "../assets/images/driver-circle.svg";
import driverOfflineIcon from "../assets/images/driver-circle-offline.svg";
import driverIdleIcon from "../assets/images/driver-circle-idle.svg";
import storeIcon from "../assets/images/marker-store.svg";
import MapStyles from "./mapStyles.json";

const GMap = (props) => {
  const googleMapRef = useRef(null);
  // list of icons
  var googleMap;
  var bounds;
  // list of the marker object along with icon
  // const [googleMap, setGoogleMap] = useState();
  const [dMarkerList, setDMarkerList] = useState([]);
  const [driverLocations, setDriverLocations] = useState(
    props.driversLocations || []
  );
  const [markers, setMarkers] = useState([]);
  const [markerList, setMarkerList] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);
  const [locateDriver, setLocateDriver] = useState({});

  var numDeltas = 100;
  var delay = 20; //milliseconds
  var i = 0;
  var deltaLat;
  var deltaLng;

  useEffect(() => {
    googleMap = initGoogleMap();
  }, []);

  function InitialMap() {
    console.log(props);

    if (props.locationsData?.drivers.length > 0) {
      setDriverLocations(props.locationsData?.drivers || []);
      setMarkers([]);
      setDMarkerList([]);

      setInitialLoading(false);
    } else if (props.chooseDriver?.id) {
      setDriverLocations(props.locationsData?.drivers || []);
      setMarkers([]);
      setDMarkerList([]);
      let newMarkers = [];
      newMarkers.push({
        lat: parseFloat(props.chooseDriver?.lat),
        lng: parseFloat(props.chooseDriver?.lng),
        icon: driverIcon,

        title: props.chooseDriver?.custom_fields?.title,
        address: props.chooseDriver.address,
        id: props.chooseDriver?.id,
        info: props.chooseDriver,
        type: "driver",
      });
      setMarkers([...newMarkers]);
    } else {
      googleMap = initGoogleMap();
      bounds = new window.google.maps.LatLngBounds();
      googleMap.setCenter({
        lat: process.env.REACT_APP_DEFAULT_LATITUDE,
        lng: process.env.REACT_APP_DEFAULT_LONGITUDE,
      });
      return;
    }
  }

  useEffect(() => {
    InitialMap();
  }, [props.locationsData]);

  useEffect(() => {
    console.log(props.isLocateDriver);
    if (props.isLocateDriver == true) {
      setLocateDriver(props.chooseDriver);
      InitialMap();
      props.setIsLocateDriver(false);
    }
  }, [props.isLocateDriver]);

  useEffect(() => {
    console.log(markers);
    if (markers.length == 0) {
      //googleMap = initGoogleMap();
      // bounds = new window.google.maps.LatLngBounds();

      if (props.locationsData?.drivers.length == 0) {
        setInitializeGoogle();
      } else {
        let newMarkers = [];
        if (props.locationsData?.drivers.length > 0) {
          props.locationsData.drivers.map((d, i) => {
            let findInd = markers.findIndex((m) => d.id == m.id);
            if (d?.lat && findInd == -1) {
              newMarkers.push({
                lat: parseFloat(d?.lat),
                lng: parseFloat(d?.lng),
                icon: driverIcon,
                title: d?.custom_fields?.title,
                address: d.address,
                id: d?.id,
                info: d,
                type: "driver",
              });
            }
          });
        }

        if (props.chooseDriver?.id) {
          let existInd = newMarkers.findIndex(
            (n) => n.id == props.chooseDriver?.id
          );
          if (existInd == -1) {
            newMarkers.push({
              lat: parseFloat(props.chooseDriver?.lat),
              lng: parseFloat(props.chooseDriver?.lng),
              icon: driverIcon,
              title: props.chooseDriver?.custom_fields?.title,
              address: props.chooseDriver.address,
              id: props.chooseDriver?.id,
              info: props.chooseDriver,
              type: "driver",
            });
          }
        }
        setMarkers([...newMarkers]);
      }
    } else {
      setInitializeGoogle();
    }
  }, [markers]);

  function setInitializeGoogle() {
    setMarkerList([...markers]);
  }

  function render({ count, position }, stats) {
    // change color if this cluster has more markers than the mean cluster
    const color =
      count > Math.max(10, stats.clusters.markers.mean) ? "#F23937" : "#F23937";
    // create svg url with fill color
    const svg = window.btoa(`
<svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
  <circle cx="120" cy="120" opacity=".6" r="70" />
  <circle cx="120" cy="120" opacity=".3" r="90" />
  <circle cx="120" cy="120" opacity=".2" r="110" />
</svg>`);
    // create marker using svg icon
    return new window.google.maps.Marker({
      position,
      icon: {
        url: `data:image/svg+xml;base64,${svg}`,
        scaledSize: new window.google.maps.Size(45, 45),
      },
      label: {
        text: String(count),
        color: "rgba(255,255,255,0.9)",
        fontSize: "12px",
      },
      title: `Cluster of ${count} markers`,
      // adjust zIndex to be above other markers
      zIndex: Number(window.google.maps.Marker.MAX_ZINDEX) + count,
    });
  }

  const showMarkers = () => {
    googleMap = initGoogleMap();
    bounds = new window.google.maps.LatLngBounds();

    if (markerList.length > 0) {
      markerList.map((x) => {
        const marker = createMarker(x);
        const infoWindow = createInfoWindow(x);
        window.google.maps.event.addListener(marker, "click", function () {
          infoWindow.close(); // Close previously opened infowindow
          infoWindow.setContent(
            '<h4 class="infowindowstyle" style="margin:0">' +
              x.title +
              '</h4><p style="margin:2px 0 0">' +
              x.address +
              "</p>"
          );
          infoWindow.open(googleMap, marker);
        });

        if (x.type == "driver") {
          let fInd = dMarkerList.findIndex((m) => m.id == marker.id);
          if (fInd == -1) {
            dMarkerList.push(marker);
            setDMarkerList([...dMarkerList]);
          }
        }

        if (props.chooseDriver?.id) {
          if (x.id == props.chooseDriver?.id) {
            infoWindow.open(googleMap, marker);
            bounds.extend(marker.position);
          }
        } else {
          bounds.extend(marker.position);
        }
      });

      //console.log(dMarkerList);
      if (dMarkerList.length > 0) {
        const markerCluster = new MarkerClusterer(
          // { googleMap, dMarkerList },
          {
            map: googleMap,
            markers: dMarkerList,
            renderer: {
              render,
            },
          }
        );
        //  console.log(markerCluster);
      }

      googleMap.setCenter(bounds.getCenter());
      googleMap.fitBounds(bounds); // the map to contain all markers
    } else {
      googleMap.setCenter({
        lat: process.env.REACT_APP_DEFAULT_LATITUDE,
        lng: process.env.REACT_APP_DEFAULT_LONGITUDE,
      });
    }
  };

  useEffect(() => {
    showMarkers();
  }, [markerList]);

  useEffect(() => {
    function transition(result) {
      i = 0;
      deltaLat = (result[0] - latitude) / numDeltas;
      deltaLng = (result[1] - longitude) / numDeltas;
      moveMarker();
    }

    function moveMarker() {
      if (dMarkerList[index]) {
        latitude += deltaLat;
        longitude += deltaLng;
        var latlng = new window.google.maps.LatLng(latitude, longitude);
        dMarkerList[index].setPosition(latlng);
        dMarkerList[index].info.location_updated = "just now";
        dMarkerList[index].lat = latitude;
        dMarkerList[index].lng = longitude;
        // setDMarkerList([...dMarkerList]);
        if (i != numDeltas) {
          i++;
          setTimeout(moveMarker, delay);
        }
      }
    }
    if (initialLoading === false) {
      var latitude = null;
      var longitude = null;
      var index = null;

      if (dMarkerList.length > 0) {
        dMarkerList.map((d, i) => {
          if (d?.id == props.updatedDriver.id) {
            latitude = d.position.lat();
            longitude = d.position.lng();
            index = i;
            var result = [props.updatedDriver.lat, props.updatedDriver.lng];
            transition(result);
            //  var latlng = new window.google.maps.LatLng(props.updatedDriver.lat, props.updatedDriver.lng );
            //  setTimeout(d.setPosition(latlng) , delay);
          }
        });
      }
    }
  }, [props.updatedDriver]);

  function showRoute(googleMap) {
    if (props.routeJsonData?.routes && props.routeJsonData?.routes.length > 0) {
      if (typeof window.google.maps.geometry !== "undefined") {
        var polyline = new window.google.maps.Polyline({
          path: window.google.maps.geometry?.encoding?.decodePath(
            props.routeJsonData?.routes[0].overview_polyline.points
          ),
          map: googleMap,
          strokeColor: "#6B6A78",
          strokeWeight: 6,
        });
      }
    }
  }

  // initialize the google map
  const initGoogleMap = () => {
    return new window.google.maps.Map(googleMapRef.current, {
      center: {
        lat:
          props.driversLocations.length > 0
            ? props.driversLocations[0]?.lat
              ? parseFloat(props.driversLocations[0].lat)
              : process.env.REACT_APP_DEFAULT_LATITUDE
            : process.env.REACT_APP_DEFAULT_LATITUDE,
        lng:
          props.driversLocations.length > 0
            ? props.driversLocations[0]?.lng
              ? parseFloat(props.driversLocations[0].lng)
              : process.env.REACT_APP_DEFAULT_LONGITUDE
            : process.env.REACT_APP_DEFAULT_LONGITUDE,
      },
      zoom: 15,
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: false,
      fullscreenControl: false,
      styles: MapStyles,
    });
  };

  // create marker on google map
  const createMarker = (markerObj) =>
    new window.google.maps.Marker({
      position: { lat: markerObj.lat, lng: markerObj.lng },
      map: googleMap,
      info: markerObj,
      title: markerObj.title,
      id: markerObj.id,
      draggable: false,
      icon: {
        url: markerObj.icon,
        // set marker width and height
        //  scaledSize: new window.google.maps.Size(40, 40),
      },
    });

  const createInfoWindow = (markerObj) =>
    new window.google.maps.InfoWindow({
      content:
        '<h4 class="infowindowstyle" style="margin:0">' +
        markerObj.title +
        '</h4><p style="margin:2px 0 0">' +
        markerObj.address +
        "</p>",
      disableAutoPan: true,
    });

  return (
    <div
      ref={googleMapRef}
      id="mapView"
      style={{
        width: "100%",
        height: "calc(100vh - 57px)",
        background: "#151515",
      }}
    />
  );
};

export default GMap;
