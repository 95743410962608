import React, { useState, useEffect } from "react";
import { GShimmer, GCardShimmer } from "./gComponents/gCardShimmer";
import GTableShimmer from "./gComponents/gTableShimmer";
import { ApiService } from "./services";
import GEmpty from "./gComponents/gEmpty";

const useDataFactory = (type, paginate = false, bodyData = {}) => {
  const EmptyPlaceholder = () => <GEmpty type={type} />;
  const LoginRequiredPlaceholder = () => (
    <GEmpty />
    // <Placeholder type={'login'} action={() => loginRedirect()} />
  );
  const internetPlaceholder = () => (
    <GEmpty type={"internet"} action={refreshData} />
  );

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [body, setBody] = useState(bodyData);
  const [pagination, setPagination] = useState({
    total: 0,
    current_page: 0,
    last_page: 1,
  });

  const [loginRequired, setLoginRequired] = useState(false);
  const [internetFailed, setInternetFailed] = useState(false);

  const fetchData = () => {
    if (paginate) {
      if (pagination.current_page == pagination.last_page) {
        return;
      }
      console.log(JSON.stringify(body));
      console.log(type);
      console.log(routes[type]);
      ApiService({
        method: "GET",
        route: routes[type] || type,
        // body: { ...body, ...{ page: pagination.current_page + 1 } },
        body: { ...body },
      }).then((response) => {
        console.log(response);
        if (response.status === 26) {
          setInternetFailed(true);
          setLoading(false);
          return;
        } else {
          setInternetFailed(false);
        }

        if (response.status === 401) {
          setLoginRequired(true);
          setLoading(false);
          return;
        } else {
          setLoginRequired(false);
        }

        setLoading(false);
        if (response.data.status_code == 1) {
          setData([...data, ...response.data?.data?.data]);
          setPagination(response.data?.data);
        } else {
          setData([...data]);
          setPagination({});
        }
      });
    } else {
      ApiService({
        method: "GET",
        route: routes[type] || type,
        body: body,
      }).then((response) => {
        console.log(response);
        if (response.status === 26) {
          setInternetFailed(true);
          setLoading(false);
          return;
        } else {
          setInternetFailed(false);
        }

        if (response.status === 401) {
          setLoginRequired(true);
          setLoading(false);
          return;
        } else {
          setLoginRequired(false);
        }
        console.log(response.data.data);
        setData(response.data.data);
        setLoading(false);
      });
    }
  };

  const loadMore = () => {
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [refresh]);

  const refreshData = (filters = {}) => {
    setBody(filters);
    setPagination({
      total: 0,
      current_page: 0,
      last_page: 1,
    });
    setData([]);
    setLoading(true);
    setRefresh(!refresh);
  };

  return {
    loading: loading,
    TableShimmer: GTableShimmer,
    CardShimmer: GCardShimmer,
    Placeholder: internetFailed
      ? internetPlaceholder
      : loginRequired
      ? LoginRequiredPlaceholder
      : EmptyPlaceholder,
    data: data,
    loadMore: loadMore,
    pagination: pagination,
    refreshData: refreshData,
    setData: setData,
  };
};

export default useDataFactory;

const routes = {
  branches: "branches",
  staff: "staff",
  host_staff: "host-staff",
  cities: "cities",
  banners: "offer-banners",
  web_plugins: "wp/homepage",
  faqs: "faqs",
  variant_types: "product/variant/types",
  timeslots: "timeslots",
  events: "events",
  task_actions: "task/actions",
  task_rules: "task/rules",
  cancel_reasons: "cancel-reasons",
  doc_types: "document/types",
  product_groups: "product/groups",
};
