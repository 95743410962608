import React, { useState } from "react";
import styled from "styled-components";
import { Info } from "@material-ui/icons";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GText from "./gText";

const CancelBox = styled.div`
  color: #000;
  background: #f1e1e2;
  font-size: 15px;
  padding: 10px;
  width: 240px;
  font-weight: 400;
  float: right;
  border: 1px solid #e26d69;
  margin-bottom: 10px;
  svg {
    color: #e26d69;
    padding-right: 10px;
    vertical-align: top;
  }
  p {
    color: #333;
    font-size: 13.5px;
    margin: 0px;
    line-height: 19px;
    display: inline-block;
    width: 80%;
    span {
      color: #e26d69;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export default function GCancelBox(props) {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <CancelBox>
      {props.handleClick ? (
        <React.Fragment>
          <Info />
          <p>
            Having issue with this booking?{" "}
            <span onClick={props.handleClick}>Click here</span> to cancel it. OR{" "}
            <br />
            <span onClick={() => setDialogOpen(true)}>
              Complete forcefully.
            </span>
          </p>
        </React.Fragment>
      ) : props.data ? (
        <React.Fragment>
          <GText
            semi
            text={props.data?.user_type? "Cancelled By - "+(props.data.user_type == 1 ? "Customer":props.data.user_type == 2 ? "Host":props.data.user_type == 3? "Driver":props.data.user_type == 4? "You" :"-"):"Cancelled"  }
            style={{ color: "#e26d69" ,width:"100%"}}
          />
          <GText
            g5
            text={props.data.reason}
            style={{ color: "#e26d69", lineHeight: "17px", marginTop: "2px" }}
          />
        </React.Fragment>
      ) : (
        ""
      )}

      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title="Complete Booking Forcefully"
          text="Please only use this option in case the normal complete option is throwing validation for incomplete tasks or booking status."
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            props.handleCompleteForcefully();
          }}
        ></GConfirmDialog>
      )}
    </CancelBox>
  );
}
