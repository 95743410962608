import React, { useState, useEffect } from "react";
import { Grid, makeStyles, TableContainer } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "./../styledComponents/tableStyle";
import { Dashboard } from "@material-ui/icons";
import "./dashboard.css";
import { ApiService, isPermissionExist, getUserType } from "../services";
import { Card } from "../styledComponents/cardStyles";
import GDashboardCounts from "../gComponents/gDashboardCounts";
import GDahboardInfo from "../gComponents/gDahboardInfo";
import GCharts from "../gComponents/gCharts";
import GEmpty from "../gComponents/gEmpty";
import GText from "../gComponents/gText";
import { Link } from "react-router-dom";
import useSettings from "../useSettings";

const columns = [
  { id: "id", label: "Id" },
  { id: "status", label: "Status", minWidth: 40 },
  { id: "customer", label: "Customer", minWidth: 80 },
  { id: "created_at_formatted", label: "Created", minWidth: 110 },
];

const paperStyle = {
  height: "420px",
  overflow: "auto",
  border: "1px solid #e8e8e8",
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

function DashboardData() {
  const classes = useStyles();
  const [dashboardData, setDashboardData] = useState({
    recent_customers: [],
    top_customers: [],
    recent_orders: [],
    graph_orders: [],
  });
  const settings = useSettings(true);

  useEffect(() => {
    ApiService({ method: "GET", route: "dashboard" })
      .then((response) => {
        console.log(response.data);
        setDashboardData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div id="dashboard" className="mainInnerView">
      {getUserType() ||
      isPermissionExist("customer_index") ||
      isPermissionExist("host_index") ||
      isPermissionExist("order_index") ? (
        <>
          <h1>
            <Dashboard /> Dashboard
          </h1>
          <div style={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
              {(getUserType() || isPermissionExist("customer_index")) &&
              dashboardData.customers ? (
                <GDashboardCounts
                  title="Total Users"
                  subtitle="Total registered users"
                  total_count={dashboardData.customers}
                  type="user"
                />
              ) : (
                ""
              )}
              {(getUserType() || isPermissionExist("order_index")) &&
              dashboardData.orders ? (
                <GDashboardCounts
                  title="Total Bookings"
                  subtitle="Total active bookings"
                  total_count={dashboardData.orders}
                  type="order"
                />
              ) : (
                ""
              )}
              {(getUserType() || isPermissionExist("host_index")) &&
              dashboardData.hosts ? (
                <GDashboardCounts
                  title="Total Hosts"
                  subtitle="Total active hosts"
                  total_count={dashboardData?.hosts}
                  type="user"
                />
              ) : (
                ""
              )}
            </Grid>
            <Grid container spacing={2}>
              {getUserType() || isPermissionExist("order_index") ? (
                <>
                  <Grid item lg={6} xs={12}>
                    <Card style={paperStyle}>
                      <GText
                        g2
                        bold
                        text={"Bookings"}
                        style={{ color: "#333", paddingLeft: "8px" }}
                      />
                      <GText
                        light
                        text={"Total Bookings for this year"}
                        style={{ padding: "0 0 10px 8px" }}
                      />
                      <GCharts
                        labels={
                          dashboardData?.graph_orders &&
                          dashboardData?.graph_orders.map((l) => {
                            return l.label;
                          })
                        }
                        values={
                          dashboardData?.graph_orders &&
                          dashboardData?.graph_orders.map((l) => {
                            return l.count;
                          })
                        }
                        height="320"
                        color="#46ca7c"
                        type="areaspline"
                        name="Booking"
                      />
                    </Card>
                  </Grid>{" "}
                  <Grid item lg={6} xs={12}>
                    <Card style={paperStyle}>
                      <GText
                        g2
                        bold
                        text={"Recent Bookings"}
                        style={{ color: "#333", paddingLeft: "8px" }}
                      />
                      <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              {columns.map((column) => (
                                <TableHeader
                                  key={column.id}
                                  style={{
                                    minWidth: column.minWidth,
                                    textAlign: column.align,
                                  }}
                                >
                                  {column.label}
                                </TableHeader>
                              ))}
                            </TableRow>
                          </TableHead>
                          {dashboardData?.recent_orders?.length !== 0 && (
                            <TableBody>
                              {dashboardData?.recent_orders &&
                                dashboardData?.recent_orders.map((o) => {
                                  return (
                                    <TableRow
                                      hover
                                      role="checkbox"
                                      tabIndex={-1}
                                      key={o.id}
                                      className="bordered"
                                    >
                                      <TableRowData
                                        style={{
                                          BorderBottom: "1px solid #f4f5f9",
                                        }}
                                      >
                                        <Link
                                          to={{
                                            pathname: "/booking/" + o.id,
                                            state: "booking",
                                          }}
                                        >
                                          #{o.id}
                                        </Link>
                                      </TableRowData>
                                      <TableRowData>
                                        <span
                                          className="orderStatus"
                                          style={{
                                            color: o.status_display?.color1
                                              ? "#" + o.status_display?.color1
                                              : "#333",
                                            background: o.status_display?.color2
                                              ? "#" + o.status_display?.color2
                                              : "transparent",
                                            padding: "5px 11px 2px",
                                            fontSize: "0.9em",
                                            fontWeight: "550",
                                          }}
                                        >
                                          {o.status_display?.title}
                                        </span>
                                      </TableRowData>
                                      <TableRowData>
                                        {o.customer.title}
                                      </TableRowData>
                                      <TableRowData className="created">
                                        {o.created}
                                      </TableRowData>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          )}
                        </Table>
                      </TableContainer>
                      {dashboardData?.recent_orders.length === 0 ? (
                        <GEmpty />
                      ) : (
                        ""
                      )}
                    </Card>
                  </Grid>
                </>
              ) : (
                ""
              )}
              {getUserType() || isPermissionExist("customer_index") ? (
                <>
                  <Grid item xs={12} lg={6}>
                    <Card style={paperStyle}>
                      <GText
                        g2
                        bold
                        text={"New Users"}
                        style={{ color: "#333", paddingLeft: "8px" }}
                      />
                      {dashboardData?.recent_customers &&
                      dashboardData.recent_customers.length === 0 ? (
                        <GEmpty />
                      ) : (
                        dashboardData?.recent_customers.map((user, i) => (
                          <div
                            key={i}
                            style={{
                              borderBottom: "1px solid rgb(226, 226, 226)",
                            }}
                          >
                            <GDahboardInfo
                              id={user.id}
                              title={user.title}
                              subtitle={user.email}
                              photo={user.thumb_photo}
                              imgType="user"
                              rightTopContent={user.created}
                              rightBottomContent=""
                              className={i % 2 ? "even" : "odd"}
                              rightTopStyle={{
                                color: "#b8b8b8",
                                fontWeight: "400",
                              }}
                            />
                          </div>
                        ))
                      )}
                    </Card>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Card style={paperStyle}>
                      <GText
                        g2
                        bold
                        text={"Top Users"}
                        style={{ color: "#333", paddingLeft: "8px" }}
                      />
                      {dashboardData.top_customers &&
                      dashboardData.top_customers.length === 0 ? (
                        <GEmpty />
                      ) : (
                        dashboardData.top_customers.map((user, i) => (
                          <div
                            key={user.id}
                            style={{
                              borderBottom: "1px solid rgb(226, 226, 226)",
                            }}
                          >
                            <GDahboardInfo
                              id={user.id}
                              title={user.title}
                              subtitle={user.email}
                              photo={user.thumb_photo}
                              imgType="user"
                              rightTopContent={user.count + "  bookings"}
                              rightBottomContent={user.created}
                              rightTopStyle={{ color: "#333" }}
                              className={i % 2 ? "even" : "odd"}
                            />
                          </div>
                        ))
                      )}
                    </Card>
                  </Grid>
                </>
              ) : (
                ""
              )}
            </Grid>
          </div>
        </>
      ) : (
        <GText
          light
          bold
          g1
          text={"Welcome to dashboard"}
          style={{
            display: "flex",
            height: "calc(100vh - 150px)",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "42px",
            textTransform: "uppercase",
          }}
        />
      )}
    </div>
  );
}

export default DashboardData;
