import React from "react";
import { Switch, Route } from "react-router-dom";
import SettingRoutes from "./settingRoutes";
import BasicSettings from "./basicSettings";
import SocialLinks from "./socialLinks";
import InvoiceSettings from "./invoiceSettings";
import AppSettings from "./appSettings";
import DocumentTypes from "./documentTypes";
import OrderCancelReasons from "./orderCancelReasonsTypes";
import FaqTypes from "./faqTypes";
import Cities from "./cities";
import BookingSettings from "./bookingSettings";
import OtherSettings from "./bookingOtherSettings";
import NotificationTypes from "./notificationsType";
import Banners from "./banners";
import PrivacyPolicy from "./privacyPolicy";
import TermsConditions from "./termsConditions";
import WebsiteSettings from "./websiteSettings";
import WebPlugins from "./websitePlugins";
import EmailSettings from "./emailSettings";
import SmsSettings from "./smsSettings";
import SmsOtpSettings from "./smsOtpSettings";
import Roles from "../roles/roles";
import StaffMembers from "./staffMembers";
import ProductGroups from "./productGroups";

const leftInnerStyle = {
  width: "23%",
  height: "calc(100vh - 55px)",
  overflow: "overlay",
  background: "#fff",
};
const rightInnerStyle = {
  width: "77%",
  height: "87vh",
  overflow: "overlay",
};
export default function Settings({ match }) {
  console.log(match);
  return (
    <div style={{ display: "flex" }}>
      <div style={leftInnerStyle}>
        <SettingRoutes url={match.url} />
      </div>
      {/* match.path should be equal to '/home' */}
      <div style={rightInnerStyle}>
        <Switch>
          <Route exact path="/settings/basic" component={BasicSettings} />
          <Route exact path="/settings/social" component={SocialLinks} />
          <Route exact path="/settings/invoice" component={InvoiceSettings} />
          <Route exact path="/settings/app" component={AppSettings} />

          <Route
            exact
            path="/settings/document-types/:type"
            component={DocumentTypes}
          />
          <Route
            exact
            path="/settings/order-cancel-reasons/:type"
            component={OrderCancelReasons}
          />
          <Route exact path="/settings/faqs/:type" component={FaqTypes} />
          <Route exact path="/settings/cities" component={Cities} />
          <Route
            exact
            path="/settings/booking-settings"
            component={BookingSettings}
          />
          <Route
            exact
            path="/settings/other-settings"
            component={OtherSettings}
          />
          <Route
            exact
            path="/settings/:type-notifications/:userType"
            component={NotificationTypes}
          />
          <Route
            exact
            path="/settings/:type-notifications/:userType/:notifyType"
            component={NotificationTypes}
          />
          <Route
            exact
            path="/settings/product-groups"
            component={ProductGroups}
          />

          <Route exact path="/settings/banners" component={Banners} />
          <Route
            exact
            path="/settings/privacy-policy"
            component={PrivacyPolicy}
          />
          <Route
            exact
            path="/settings/terms-conditions"
            component={TermsConditions}
          />

          <Route
            exact
            path="/settings/website-settings"
            component={WebsiteSettings}
          />

          <Route exact path="/settings/web-plugin" component={WebPlugins} />

          <Route
            exact
            path="/settings/email-notifications"
            component={EmailSettings}
          />
          <Route
            exact
            path="/settings/sms-notifications"
            component={SmsSettings}
          />
          <Route
            exact
            path="/settings/sms-otp-notifications"
            component={SmsOtpSettings}
          />
          <Route
            exact
            path="/settings/staff-members"
            component={StaffMembers}
          />
          <Route exact path="/settings/roles/:roleType" component={Roles} />
        </Switch>
      </div>
    </div>
  );
}
