import React, { useState, useEffect } from "react";
import { Edit } from "@material-ui/icons";
import { ApiService, getUserType, isPermissionExist } from "../services";
import GInfo from "../gComponents/gInfo";
import { Card, CardHead } from "../styledComponents/cardStyles";
import { GContent } from "../gComponents/gContent";
import { Switch, Route, useParams } from "react-router-dom";
import GTabs from "../gComponents/gTabs";
import GSwitch from "../gComponents/gSwitch";
import Bookings from "../bookings/ordersList";
import Documents from "../components/documentsList";
import { useSnackbar } from "react-simple-snackbar";
import HostForm from "../forms/hostForm";
import useSettings from "../useSettings";
import GRouting from "../gComponents/gRouteBreadCrumbs";
import useDataFactory from "../useDataFactory";
import Modal from "react-modal";
import GNoFound from "../gComponents/gNoFound";
import HostStaffMembers from "../components/hostStaffMembers";

const RoutLinks = [
  {
    title: "Hosts",
    link: "/hosts",
    state: "host",
  },
];

export default function HostDetail() {
  let { hostId, detailType } = useParams();
  const [openSnackbar] = useSnackbar();
  const [chvisible, setChvisible] = useState(false);
  const settings = useSettings(true);
  const {
    data: hostData,
    setData,
    refreshData,
  } = useDataFactory("hosts/" + hostId, false);

  const driverRoutes = [
    {
      title: "bookings",
      link: "/host/" + hostId + "/bookings",
      state: "host",
    },
    getUserType()
      ? {
          title: "documents",
          link: "/host/" + hostId + "/documents",
          state: "host",
        }
      : "",
    getUserType()
      ? {
          title: "staff",
          link: "/host/" + hostId + "/staff",
          state: "host",
        }
      : "",
  ];

  function toggleValue(newValue, selectedFieldId) {
    ApiService({
      method: "PUT",
      route: "hosts/" + selectedFieldId,
      body: {
        host_status: newValue,
      },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          hostData.host_status = newValue;
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleUpdateImage(imgValue, id, thumbImg) {
    console.log(imgValue);
    ApiService({
      method: "PUT",
      route: "hosts/" + hostId,
      body: { photo: imgValue, thumb_photo: thumbImg },
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        hostData.photo = response.data.data.photo;
        hostData.thumb_photo = response.data.data.thumb_photo;
      }
      openSnackbar(response.data.message);
      return;
    });
  }
  return (
    <div className="mainInnerView">
      <GRouting links={RoutLinks} currentPage={hostData.title} />
      {hostData ? (
        <GInfo
          id={hostData.id}
          title={hostData.title}
          key={hostData.id}
          className="headerInfo"
          imgType="host"
          photo={hostData?.photo}
          imgEdit={
            getUserType() || isPermissionExist("host_update") ? true : false
          }
          uploadImage={handleUpdateImage}
          style={{ width: "auto" }}
        />
      ) : (
        ""
      )}
      {getUserType() || isPermissionExist("host_update") ? (
        <GSwitch
          id={hostData.id}
          uId={hostData.id}
          currentValue={hostData.host_status}
          disabled={
            getUserType() || isPermissionExist("host_update") ? false : true
          }
          style={{
            margin: "8px 0 0 25px",
            display: "inline-block",
            verticalAlign: "top",
          }}
          onSwitchChange={toggleValue}
        />
      ) : (
        ""
      )}

      <br />

      <div
        style={{
          width: "30%",
          display: "inline-block",
          padding: "10px",
          verticalAlign: "top",
          boxSizing: "border-box",
        }}
      >
        <Card style={{ minHeight: "200px" }}>
          <CardHead>
            Contact Details
            {getUserType() || isPermissionExist("host_update") ? (
              <Edit onClick={() => setChvisible(true)} />
            ) : (
              ""
            )}
          </CardHead>
          <GContent title="Email" value={hostData.email} />
          <GContent title="Phone" value={hostData.phone} />
        </Card>

        <Modal
          isOpen={chvisible}
          className="modal modalRight"
          overlayClassName="modal-overlay"
          onRequestClose={() => setChvisible(false)}
          style={{
            content: {
              width: "400px",
            },
          }}
          contentLabel="Host Form Modal"
        >
          <HostForm
            chvisible={chvisible}
            setChvisible={setChvisible}
            data={hostData}
            onSubmit={(data) => {
              setData({ ...hostData, ...data });
            }}
            settings={settings}
          />
        </Modal>
      </div>

      <div
        style={{
          width: "65%",
          display: "inline-block",
          padding: "10px",
          verticalAlign: "top",
        }}
      >
        <GTabs routes={driverRoutes} active={detailType}></GTabs>

        <Switch>
          <Route exact path={"/host/" + hostId + "/bookings"}>
            <Bookings type="host" value={hostId} title="host_id" />
          </Route>

          {getUserType() ? (
            <Route exact path={"/host/" + hostId + "/documents"}>
              <Documents type="host" value={hostId} title="host_id" />
            </Route>
          ) : (
            ""
          )}
          {getUserType() ? (
            <Route exact path={"/host/" + hostId + "/staff"}>
              <HostStaffMembers type="host" value={hostId} title="host_id" />
            </Route>
          ) : (
            ""
          )}
          <Route exact path={"/host/" + hostId + "/not"}>
            <GNoFound />
          </Route>
        </Switch>
      </div>
    </div>
  );
}
