import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./css/colors.css";
import "./css/forms.css";
import "./css/modal.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";
import SnackbarProvider from "react-simple-snackbar";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";

const theme = createMuiTheme({
  palette: {
    primary: blue,
    background: {
      default: "#252629",
      paper: "#fff",
    },
    type: "light",
  },
  borders: {
    border: {
      default: "1px solid red",
      paper: "1px soid #e8e8e8",
    },
  },
  palette1: {
    primary: blue,
    background: {
      default: "#252629",
      paper: "#37383D",
    },
    type: "dark",
  },
  typography: {
    body1: {
      fontSize: 14,
      fontWeight: 500,
      fontFamily:
        "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    },
  },
  a: {
    color: {
      default: "#F8310E",
    },
  },
});

function Main() {
  return (
    <React.StrictMode>
      <Router>
        <SnackbarProvider>
          <MuiThemeProvider theme={theme}>
            <App />
          </MuiThemeProvider>
        </SnackbarProvider>
      </Router>
    </React.StrictMode>
  );
}

ReactDOM.render(<Main />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
