import React, { useState, useEffect, useRef } from "react";
import { TableContainer } from "@material-ui/core";
import { useSnackbar } from "react-simple-snackbar";
import {
  ImgUrl,
  ApiService,
  getUserType,
  isPermissionExist,
  getProductTitle,
} from "../services";
import { ShoppingBasket, Edit, Delete } from "@material-ui/icons";
import { ReactComponent as ProductIcon } from "../assets/images/sidebar/products.svg";
import itemPlaceholder from "../assets/images/placeholders/p-item.png";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import GEmpty from "../gComponents/gEmpty";
import GTableShimmer from "../gComponents/gTableShimmer";
import GButton from "../gComponents/gButton";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GPagination from "../gComponents/gPagination";
import { useHistory, Link } from "react-router-dom";
import ProductCreatePopup from "../forms/productCreateForm";
import ProductEditPopup from "../forms/productEditForm";
import QueryWith from "../Query";
import GSelectbox from "../gComponents/gSelectbox";
import { GSearch } from "../gComponents/gSearch";
import { HostFilter } from "../components/hostFilterComponent";
import GSwitch from "../gComponents/gSwitch";
import useSettings from "../useSettings";
import Modal from "react-modal";

const statusFilters = [
  {
    title: "Active",
    value: "1",
  },
  {
    title: "Inactive",
    value: "0",
  },
];

export default function Products(props) {
  const [openSnackbar] = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [productsData, setProductsData] = useState([]);
  const [productsPaginationData, setProductsPaginationData] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedProductData, setSelectedProductData] = useState({});
  const [selectedProductId, setSelectedProductId] = useState("");
  const [pcvisible, setPcvisible] = useState(false);
  const [pevisible, setPevisible] = useState(false);
  const settings = useSettings(true);
  const [productFormKeys, setProductFormKeys] = useState([]);
  const [productEditFormKeys, setProductEditFormKeys] = useState([]);

  let history = useHistory();
  let params = new URLSearchParams(history.location.search);
  const [filters, setFilters] = useState({
    page: params.get("page") ? params.get("page") : "1",
    search: params.get("search") ? params.get("search") : "",
    host_id: params.get("host_id") ? params.get("host_id") : "",
    status: params.get("status") ? params.get("status") : "",
  });

  const columns = [
    { id: "title", label: "Title", minWidth: 130 },
    { id: "photo", label: "Photo", minWidth: 50, type: "image" },

    { id: "status", label: "Status", minWidth: 60 },
    getUserType() ||
    isPermissionExist("product_update") ||
    isPermissionExist("product_destroy")
      ? { id: "actions", label: "Actions", minWidth: 60, align: "center" }
      : "",
  ];

  useEffect(() => {
    ApiService({ method: "OPTIONS", route: "products" })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          setProductFormKeys(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const getProducts = () => {
    if (!filters.host_id) {
      return;
    }
    var params = QueryWith(history.location, filters);
    if (params.length > 0) {
      history.push({
        pathname: "/products",
        state: "product",
        search: params[0],
      });
    }
    ApiService({ method: "GET", route: "products", body: filters })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          setProductsData(response.data.data.data);
          setProductsPaginationData(response.data.data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!filters.host_id) {
      setProductsData([]);
      setProductsPaginationData("");
      return;
    }
    setIsLoading(true);
    getProducts();
  }, [filters.host_id]);

  useEffect(() => {
    getProducts();
  }, [filters.search, filters.status, filters.page]);

  function addDefaultLogo(e) {
    e.target.src = itemPlaceholder;
  }

  const deleteProduct = () => {
    ApiService({ method: "DELETE", route: "products/" + selectedProductId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          getProducts();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangePage = (pageUrl) => {
    console.log(pageUrl);
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setFilters({ ...filters, page: NewPageNo });
  };

  const handleToggleStatus = (newVal, id, ind) => {
    ApiService({
      method: "PUT",
      route: "products/" + id,
      body: {
        status: newVal,
      },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          productsData[ind].status = newVal;
          setProductsData([...productsData]);
        } else {
          getProducts();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (isLoading == false) {
      setPevisible(true);
    }
  }, [productEditFormKeys]);

  return (
    <div className="mainInnerView">
      <h1>
        <ProductIcon style={{ fill: "#000" }} /> {getProductTitle()}
        <HostFilter
          value={filters.host_id}
          isStored={true}
          style={{
            marginLeft: "auto",
            float: "right",
          }}
          handleHostChange={(value) => {
            console.log(value);
            if (value) {
              setFilters({ host_id: value, page: "1" });
            } else {
              setFilters({ host_id: value || "" });
            }
          }}
          handleClick={(v) => setFilters({ host_id: v || "" })}
        />
      </h1>

      {filters.host_id ? (
        <React.Fragment>
          <div
            style={{
              display: "inline-flex",
              width: "60%",
              alignItems: "center",
              gap: "10px",
              flexWrap: "wrap",
              verticalAlign: "top",
            }}
          >
            <GSearch
              placeholder="Search by title.."
              value={filters.search}
              handleChange={(val) => {
                setFilters({ ...filters, search: val, page: 1 });
              }}
            />

            <GSelectbox
              placeholder="All"
              defaultvalue={filters.status}
              data={statusFilters}
              id="item_status_filter"
              option_key="title"
              option_value="value"
              onChange={(newVal) => {
                setFilters({ ...filters, status: newVal, page: 1 });
              }}
              style={{
                border: "1px solid #e2e2e2",
                height: "40px",
                minWidth: "150px",
              }}
            />
          </div>
          <div
            style={{
              display: "inline-block",
              width: "40%",
              paddingTop: "5px",
            }}
          >
            {getUserType() || isPermissionExist("product_store") ? (
              <GButton
                variant="condensed"
                children="Add"
                onClick={() => {
                  setPcvisible(true);
                }}
                style={{
                  float: "right",
                  marginTop: "-5px",
                  padding: "0.6rem 2rem",
                }}
              />
            ) : (
              ""
            )}
            {productsData.length > 0 && (
              <GPagination
                count={productsPaginationData.total}
                from={productsPaginationData.from}
                to={productsPaginationData.to}
                onChangeNextPage={() =>
                  handleChangePage(productsPaginationData.next_page_url)
                }
                onChangePrevPage={() =>
                  handleChangePage(productsPaginationData.prev_page_url)
                }
                rowsPerPage={productsPaginationData.per_page}
                prevDisabled={
                  productsPaginationData.prev_page_url ? false : true
                }
                nextDisabled={
                  productsPaginationData.next_page_url ? false : true
                }
                listStyle={{ margin: "10px 0 0" }}
              ></GPagination>
            )}
          </div>

          <div className="listPaper" style={{ marginTop: "15px" }}>
            {isLoading === true ? (
              <GTableShimmer></GTableShimmer>
            ) : (
              <TableContainer>
                {productsData.length === 0 ? (
                  <GEmpty></GEmpty>
                ) : (
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns
                          .filter((c) => c.id)
                          .map((column, ind) => {
                            return (
                              <TableHeader
                                key={column.id}
                                style={{
                                  minWidth: column.minWidth,
                                  textAlign: column.align,
                                }}
                              >
                                {column.label}
                              </TableHeader>
                            );
                          })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {productsData.map((c, i) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={c.id}
                          >
                            <TableRowData
                              style={{
                                BorderBottom: "1px solid #f4f5f9",
                              }}
                            >
                              {c.title || c.custom_fields?.title}
                            </TableRowData>
                            <TableRowData>
                              <img
                                alt={c.custom_fields?.title}
                                src={
                                  c.images && c.images.length > 0
                                    ? ImgUrl("item") +
                                      "/" +
                                      c.images[0].thumb_photo
                                    : ""
                                }
                                onError={addDefaultLogo}
                                style={{
                                  height: "45px",
                                  width: "45px",
                                  borderRadius: "50%",
                                }}
                              />
                            </TableRowData>
                            <TableRowData>
                              <GSwitch
                                id={"item" + c.id}
                                uId={c.id}
                                currentValue={
                                  c.status === "true" ||
                                  c.status === "1" ||
                                  c.status === 1
                                    ? 1
                                    : 0
                                }
                                disabled={
                                  getUserType() ||
                                  isPermissionExist("product_update")
                                    ? false
                                    : true
                                }
                                onSwitchChange={(newVal, id) =>
                                  handleToggleStatus(newVal, id, i)
                                }
                              />
                            </TableRowData>
                            {getUserType() ||
                            isPermissionExist("product_update") ||
                            isPermissionExist("product_destroy") ? (
                              <TableRowData
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {getUserType() ||
                                isPermissionExist("product_update") ? (
                                  <GButton
                                    variant="linkable"
                                    onClick={(e) => {
                                      setSelectedProductData(c);
                                      let editFormKeys = [...productFormKeys];
                                      editFormKeys.map((f) => {
                                        f.value = c.custom_fields[f.id];
                                      });
                                      setProductEditFormKeys([...editFormKeys]);
                                      console.log(editFormKeys);

                                      // setPevisible(true);
                                    }}
                                  >
                                    <Edit fontSize="small" />
                                  </GButton>
                                ) : (
                                  ""
                                )}
                                {getUserType() ||
                                isPermissionExist("product_destroy") ? (
                                  <GButton
                                    variant="linkable"
                                    //  onClick={(e) =>
                                    //       window.confirm(
                                    //         "Are you sure you wish to delete this customer?"
                                    //       ) && deleteCustomer(c.id)
                                    // }

                                    onClick={(e) => {
                                      setSelectedProductId(c.id);
                                      setDialogOpen(true);
                                    }}
                                  >
                                    <Delete fontSize="small" />
                                  </GButton>
                                ) : (
                                  ""
                                )}
                              </TableRowData>
                            ) : (
                              ""
                            )}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
            )}
          </div>
        </React.Fragment>
      ) : (
        <div className="listPaper" style={{ marginTop: "15px" }}>
          <GEmpty
            title={getProductTitle()}
            subtitle={
              "Please choose the host to fetch the " +
              getProductTitle() +
              " list"
            }
          />
        </div>
      )}

      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title={"Delete " + getProductTitle()}
          text={
            "Are you sure you want to delete this " + getProductTitle() + "?"
          }
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteProduct();
          }}
        ></GConfirmDialog>
      ) : (
        ""
      )}

      <Modal
        isOpen={pcvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setPcvisible(false)}
        style={{
          content: {
            width: "500px",
          },
        }}
        contentLabel="Product Create Form Modal"
      >
        <ProductCreatePopup
          pcvisible={pcvisible}
          setPcvisible={setPcvisible}
          hostId={filters.host_id}
          onSubmit={getProducts}
          settings={settings}
          fields={productFormKeys}
        />
      </Modal>

      <Modal
        isOpen={pevisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setPevisible(false)}
        style={{
          content: {
            width: "500px",
          },
        }}
        contentLabel="Product Edit Form Modal"
      >
        <ProductEditPopup
          pevisible={pevisible}
          setPevisible={setPevisible}
          hostId={filters.host_id}
          data={selectedProductData}
          settings={settings}
          onSubmit={getProducts}
          fields={productEditFormKeys}
        />
      </Modal>
    </div>
  );
}
