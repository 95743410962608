import React, { useState } from "react";
import {
  ApiService,
  addDefaultSrc,
  ImgUrl,
  getProductTitle,
} from "../services";
import { Slider } from "@material-ui/core";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GImageEditor from "../gComponents/gImageEditor";
import GButton from "../gComponents/gButton";
import GText from "../gComponents/gText";
import { Delete } from "@material-ui/icons";
import GMap from "../gComponents/gMaps";
import useDataFactory from "../useDataFactory";
import GTagsInput from "../gComponents/gTagsInput";
import styled from "styled-components";

const Div = styled.div`
.MuiSlider-root {
    color: #F8310E;
    `;

export default function ProductCreateForm(props) {
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [openSnackbar] = useSnackbar();
  const [productImg, setProductImg] = useState([]);
  const { data: groupsData } = useDataFactory("product_groups", false);

  const initialValues = props.fields.reduce(
    (a, v) => ({
      ...a,
      [v?.id]: ["checkbox"].includes(v?.type) ? 0 : "",
    }),
    {
      address: "",
      lat: process.env.REACT_APP_DEFAULT_LATITUDE || 30.7046,
      lng: process.env.REACT_APP_DEFAULT_LONGITUDE || 76.7179,
      price_from: 0,
      price_upto: 30,
      booking_reminder: 0,
      featured: 0,
      booking_sms: "",
      booking_sms_delay: "",
      groups: [],
    }
  );

  function salaryValueLabelFormat(value) {
    return `${Math.round(value)}`;
  }
  const onSubmit = (values) => {
    setIsBtnDisabled(true);
    let sendData = {
      custom_fields: values,
      host_id: props.hostId,
      address: values.address,
      lat: values.lat,
      lng: values.lng,
      price_from: values.price_from || 0,
      price_upto: values.price_upto || 30,
      user_cost: values.user_cost || 0,
      users_covered: values.users_covered || 1,
      units: values.units,
      min_users: values.min_users,
      max_users: values.max_users,
      booking_reminder: values.booking_reminder,
      featured: values.featured,
      booking_sms: values.booking_sms,
      booking_sms_delay: values.booking_sms_delay || 0,
      groups: values.groups || [],
    };
    if (productImg.length > 0) {
      sendData.photo = productImg[0]?.photo;
      sendData.thumb_photo = productImg[0]?.thumb_photo;
    }
    console.log(sendData);
    ApiService({
      method: "POST",
      route: "products",
      body: sendData,
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        props.setPcvisible(false);
        props.onSubmit();
      }
      setIsBtnDisabled(false);
      openSnackbar(response.data.message);
    });
  };

  const validationSchema = Yup.object({
    address: Yup.string().required(),
  }).shape(
    props.fields
      .filter((t) => t.required)
      .reduce(
        (a, v) => ({
          ...a,
          [(v?.id).toString()]:
            v.type == "checkbox"
              ? ""
              : Yup.string()
                  .nullable()
                  .required(v.title + " is required"),
        }),
        {}
      )
  );

  if (props.pcvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header"> Add {getProductTitle()}</div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <GMap
                centerLocation={{
                  lat: process.env.REACT_APP_DEFAULT_LATITUDE || 30.7046,
                  lng: process.env.REACT_APP_DEFAULT_LONGITUDE || 76.7179,
                }}
                search={true}
                height="30vh"
                type="createStore"
                onChange={(address, loc) => {
                  values.lat = loc.lat();
                  values.lng = loc.lng();
                  setFieldValue("address", address.address);
                }}
                label="Location"
                padding={true}
              />
              <FormikControl
                control="input"
                type="text"
                label="Address"
                name="address"
              />
              <Div
                style={{
                  padding: "6px 15px",
                }}
              >
                <label>
                  Price ({props.settings.currency_symbol + values.price_from} -{" "}
                  {props.settings.currency_symbol + values.price_upto})
                </label>
                <Slider
                  min={0}
                  max={100}
                  step={1}
                  defaultValue={[
                    values.price_from || 0,
                    values.price_upto || 30,
                  ]}
                  onChange={(e, val) => {
                    console.log(e);
                    console.log(val);
                    setFieldValue("price_from", val[0]);
                    setFieldValue("price_upto", val[1]);
                  }}
                  valueLabelDisplay="auto"
                  aria-labelledby="range-slider"
                  getAriaValueText={salaryValueLabelFormat}
                  valueLabelFormat={salaryValueLabelFormat}
                />
              </Div>
              {props.fields.map((f, i) => (
                <React.Fragment key={i}>
                  {f.type == "select" && (
                    <FormikControl
                      control="selectOption"
                      as="select"
                      label={f.title}
                      name={f.id.toString()}
                      options={f.answers}
                      key_title="title"
                      key_value="id"
                      divstyle={{
                        display: "inline-block",
                        width: "50%",
                        boxSizing: "border-box",
                        verticalAlign: "top",
                      }}
                    />
                  )}

                  {f.type == "text" && (
                    <FormikControl
                      control="input"
                      type="text"
                      label={f.title}
                      name={f.id}
                    />
                  )}

                  {f.type == "textarea" && (
                    <FormikControl
                      control="input"
                      as="textarea"
                      label={f.title}
                      name={f.id}
                    />
                  )}

                  {f.type == "checkbox" && (
                    <FormikControl
                      control="singleCheckbox"
                      as="checkbox"
                      label={f.title}
                      name={f.id}
                      checked={values[f.id] == 1 ? true : false}
                      onChange={(e) => {
                        values[f.id] = e.target.checked == true ? 1 : 0;
                        setFieldValue(f.id, e.target.checked == true ? 1 : 0);
                      }}
                    />
                  )}
                </React.Fragment>
              ))}

              <div style={{ margin: "15px 15px 25px" }}>
                <label>Groups</label>
                <GTagsInput
                  selectedData={values.groups}
                  data={groupsData}
                  placeholder={"Select Groups"}
                  onSubmit={(data) => {
                    console.log(data);
                    values.groups = data;
                    setFieldValue("groups", data);
                  }}
                  showButton={false}
                />
              </div>

              <FormikControl
                control="toggle"
                label={"Allow Notification Reminders"}
                isToggled={values.booking_reminder}
                name="booking_reminder"
                onToggle={() => {
                  setFieldValue(
                    "booking_reminder",
                    values.booking_reminder == 1 ? 0 : 1
                  );
                }}
                style={{
                  display: "inline-block",
                  width: "50%",
                  boxSizing: "border-box",
                  verticalAlign: "top",
                }}
              />

              <FormikControl
                control="toggle"
                label={"Featured"}
                isToggled={values.featured}
                name="featured"
                onToggle={() => {
                  setFieldValue("featured", values.featured == 1 ? 0 : 1);
                }}
                style={{
                  display: "inline-block",
                  width: "50%",
                  boxSizing: "border-box",
                  verticalAlign: "top",
                }}
              />

              <FormikControl
                control="input"
                as="textarea"
                label={"Booking SMS"}
                name="booking_sms"
              />

              <FormikControl
                control="input"
                type="text"
                label={"Booking SMS Delay (in mins)"}
                name={"booking_sms_delay"}
                onChange={(event) => {
                  const re = /^[0-9\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("booking_sms_delay", event.target.value);
                  }
                }}
                divstyle={{
                  display: "inline-block",
                  width: "50%",
                  boxSizing: "border-box",
                  verticalAlign: "top",
                }}
              />

              <div style={{ margin: "15px" }}>
                <GText semi g2 text={getProductTitle() + " Images"} />
                {productImg.map((b, ind) => {
                  return (
                    <div
                      style={{ position: "relative", display: "inline-block" }}
                      key={ind}
                    >
                      <img
                        alt=""
                        src={ImgUrl("item") + "/" + b.photo}
                        onError={addDefaultSrc}
                        style={{
                          width: "180px",
                          height: "140px",
                          padding: "5px",
                          border: "1px solid #e2e2e2",
                          objectFit: "cover",
                          margin: "5px",
                        }}
                      />
                      <Delete
                        style={{
                          background: "#000",
                          fontSize: "1rem",
                          color: "#fff",
                          padding: "3px",
                          position: "absolute",
                          right: "10px",
                          top: "10px",
                        }}
                        onClick={(e) => {
                          productImg.splice(ind, 1);
                          setProductImg([...productImg]);
                          values.images = [...productImg];
                        }}
                      />
                    </div>
                  );
                })}

                {productImg.length == 0 && (
                  <GImageEditor
                    onSubmit={(newValue, id, thumb_val) => {
                      setProductImg([
                        ...productImg,
                        { photo: newValue, thumb_photo: thumb_val },
                      ]);
                      values.images = [
                        ...productImg,
                        { photo: newValue, thumb_photo: thumb_val },
                      ];
                    }}
                    id="thumb_photo"
                    image={
                      values.photo ? ImgUrl("item") + "/" + values.photo : ""
                    }
                    type="item"
                    style={{ height: "140px", paddingTop: "50px" }}
                    divstyle={{
                      margin: "0 auto",
                      display: "inline-flex",
                      minWidth: "220px",
                    }}
                  />
                )}
              </div>

              <GButton
                type="submit"
                disabled={isBtnDisabled}
                variant="condensed"
                children="Create"
                style={{ width: "calc(100% - 30px)", margin: "15px" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
