import React, { useState, useEffect } from "react";
import { Switch, Route, useParams } from "react-router-dom";
import { ApiService } from "../services";
import { Add } from "@material-ui/icons";
import GButton from "../gComponents/gButton";
import GTabs from "../gComponents/gTabs";
import GListCard from "../gComponents/gListCards";
import { useSnackbar } from "react-simple-snackbar";
import DocumentTypeForm from "../forms/documentTypesForm";
import GText from "../gComponents/gText";
import useDataFctory from "../useDataFactory";
import Modal from "react-modal";

const docRoutes = [
  {
    title: "customer",
    link: "/settings/document-types/customer",
    state: "setting",
  },
  {
    title: "host",
    link: "/settings/document-types/host",
    state: "setting",
  },
];

export default function DocumentTypes() {
  const [typeValue, setTypeValue] = React.useState("customer");

  return (
    <div className="rightInnerView">
      <GText g2 bold text={"Document Types"} style={{ marginBottom: "20px" }} />

      <GTabs routes={docRoutes} active={typeValue}></GTabs>

      <Switch>
        <div className="listPaper">
          <Route path="/settings/document-types/:type">
            <Child onChange={(newValue) => setTypeValue(newValue)} />
          </Route>
        </div>
      </Switch>
    </div>
  );
}

function Child(props) {
  let { type } = useParams();
  let typeId = type === "customer" ? "1" : type === "host" ? "2" : "";
  const [selectedDocType, setSelectedDocType] = useState({});
  const [dtvisible, setDtvisible] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [openSnackbar] = useSnackbar();
  const [initialLoading, setInitialLoading] = useState(true);
  const {
    loading,
    Placeholder,
    data: docTypeData,
    CardShimmer,
    refreshData,
  } = useDataFctory("doc_types", false, { user_type: typeId });

  useEffect(() => {
    if (initialLoading == false) {
      refreshData({ user_type: typeId });
      props.onChange(type);
    }

    setInitialLoading(false);
  }, [type]);

  function deleteDocType(selectedId) {
    ApiService({ method: "DELETE", route: "document/types/" + selectedId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          let selectedIndex = docTypeData.findIndex((p) => p.id == selectedId);
          if (selectedIndex > -1) {
            docTypeData.splice(selectedIndex, 1);
          }
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div>
      <GButton
        variant="linkable"
        children="Add"
        onClick={() => {
          setIsEmpty(true);
          setSelectedDocType({});
          setDtvisible(true);
        }}
        style={{
          margin: "-60px -15px 0px",
          float: "right",
        }}
      >
        <Add style={{ fontSize: "1.8rem" }} />
      </GButton>

      {loading === true ? (
        <CardShimmer />
      ) : docTypeData.length === 0 ? (
        <Placeholder />
      ) : (
        docTypeData.map((d) => {
          return (
            <GListCard
              setting="Document Type"
              id={d.id}
              title={d.title}
              listActions={true}
              key={d.id}
              onEdit={(selectedRecord) => {
                setSelectedDocType(selectedRecord);
                setIsEmpty(false);
                setDtvisible(true);
              }}
              onDelete={deleteDocType}
              data={d}
            ></GListCard>
          );
        })
      )}

      <Modal
        isOpen={dtvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setDtvisible(false)}
        style={{
          content: {
            width: "400px",
          },
        }}
        contentLabel="Document Type Form Modal"
      >
        <DocumentTypeForm
          dtvisible={dtvisible}
          setDtvisible={setDtvisible}
          isEmpty={isEmpty}
          type={typeId}
          data={selectedDocType}
          onSubmit={(data) => {
            let selectedIndex = docTypeData.findIndex((p) => p.id == data.id);
            if (selectedIndex > -1) {
              docTypeData[selectedIndex] = { ...data };
            } else {
              docTypeData.push(data);
            }
          }}
        />
      </Modal>
    </div>
  );
}
